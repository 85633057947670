import { AppLayout } from '@cloudscape-design/components-themed';
import Navigation from '@risksmart-app/components/Navigation';
import { useNavMenu } from '@risksmart-app/components/Navigation/NavMenuProvider';
import { useTools } from '@risksmart-app/components/Tools/ToolsProvider';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavItems } from 'src/routes/useNavItems';

import { ChangeRequestLevels } from '@/components/ChangeRequestLevels/ChangeRequestLevels';
import { CountOptions } from '@/components/ConnectedCount';
import ConnectedCount from '@/components/ConnectedCount';
import HelpPanel from '@/components/HelpPanel';
import NotificationsList from '@/components/NotificationsList';
import { useCustomOrgLogo } from '@/hooks/use-custom-org-logo';
import { logoutUrl } from '@/utils/urls';

import styles from './style.module.scss';

type Props = {
  children: ReactNode;
  panelContent?: ReactNode;
};

export const AuthenticatedAppLayout: FC<Props> = ({
  children,
  panelContent,
}) => {
  const [navigationOpen, setNavigationOpen] = useNavMenu();
  const navItems = useNavItems();
  const navigationWidth = navigationOpen ? 300 : 68;
  const maxContentWidth = 1440;
  const [toolsContent, setToolsContent] = useTools();
  const [searchParams, setSearchParams] = useSearchParams();

  let tools: ReactNode | undefined = undefined;
  const toolsType = toolsContent?.split(':')[0];
  const id = toolsContent?.split(':')[1];
  const secondaryId = toolsContent?.split(':')[2];
  switch (toolsType) {
    case 'help':
      tools = <HelpPanel />;
      break;
    case 'notifications':
      tools = <NotificationsList />;
      break;
    case 'page-content':
      tools = panelContent;
      break;
    case 'change-request':
      if (id) {
        tools = (
          <ChangeRequestLevels parentId={id} changeRequestId={secondaryId} />
        );
      }
      break;
    default:
      tools = undefined;
  }

  const getCustomLogo = useCustomOrgLogo();

  // TODO check `toolsHide` is still needed in UIv3
  // Using the `toolsHide` prop causes the UI to jump, using `visibility: hidden` instead
  return (
    <div className={clsx(styles.layout, 'App', !tools && styles.hideTools)}>
      <AppLayout
        content={
          <div className="flex h-full">
            <div
              className="flex-none h-screen fixed overflow-auto print:hidden"
              style={{
                top: 0,
                bottom: 0,
                width: navigationWidth,
              }}
            >
              <Navigation
                showUserMenu={true}
                renderCount={(props) => (
                  <ConnectedCount
                    {...props}
                    countName={props.countName as CountOptions}
                  />
                )}
                navItems={navItems}
                logoutUrl={logoutUrl()}
                navigationOpen={navigationOpen}
                setNavigationOpen={setNavigationOpen}
                customLogoUrl={getCustomLogo}
              />
            </div>
            <div
              className={clsx('flex-grow', styles.rsContent)}
              style={{
                paddingLeft: navigationWidth,
              }}
            >
              {children}
            </div>
          </div>
        }
        maxContentWidth={maxContentWidth}
        disableContentPaddings
        navigationOpen={false}
        onNavigationChange={() => {
          // Do nothing. Setting just to avoid warning in console
        }}
        navigationHide={true}
        toolsWidth={350}
        tools={tools}
        toolsHide={false}
        toolsOpen={!!toolsContent}
        onToolsChange={({ detail }) => {
          if (
            !detail.open &&
            searchParams.get('showRequest') === 'true' &&
            toolsType === 'change-request'
          ) {
            setSearchParams((prev) => {
              prev.set('showRequest', 'false');

              return prev;
            });
          }
          setToolsContent(detail.open ? 'help' : undefined);
        }}
        navigationWidth={navigationWidth}
      />
    </div>
  );
};

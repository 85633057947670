import { FormField } from '@cloudscape-design/components-themed';
import Input from '@cloudscape-design/components-themed/input';
import { ControlProps } from '@jsonforms/core';

export const InputControl = (props: ControlProps) => {
  return (
    <FormField label={props.label} errorText={props.errors}>
      <Input
        type="text"
        name={props.path}
        value={props.data}
        disabled={!props.enabled}
        placeholder={props.uischema?.options?.['placeholder'] ?? ''}
        onChange={(ev) => props.handleChange(props.path, ev.detail.value)}
      />
    </FormField>
  );
};

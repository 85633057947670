import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import { AssessmentTypeEnum } from 'src/pages/assessments/useAssessmentTypeConfig';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import { filterEmptyTabs } from '@/utils/tabUtils';

import CreateTab from '../create/AssessmentResultCreateTab';
import UpdateTab from './AssessmentResultUpdateTab';

export const useTabs = (
  assessmentMode: AssessmentTypeEnum,
  assessment: ObjectWithContributors | undefined = undefined,
  resultMode: 'add' | 'update',
  showAssessmentSelector: boolean,
  navigateToResults: boolean
) => {
  const { t } = useTranslation('common');

  const canViewDocumentAssessmentResults = useHasPermission(
    'read:document_assessment_result',
    assessment
  );
  const canViewObligationAssessmentResults = useHasPermission(
    'read:obligation_assessment_result',
    assessment
  );

  const canViewRiskAssessmentResults = useHasPermission(
    'read:risk_assessment_result',
    assessment
  );
  const canViewAssessmentResults =
    canViewDocumentAssessmentResults ||
    canViewObligationAssessmentResults ||
    canViewRiskAssessmentResults;

  const tabs: TabsProps.Tab[] = [];

  if (canViewAssessmentResults) {
    tabs.push({
      label: t('details'),
      id: 'details',
      content:
        resultMode === 'add' ? (
          <CreateTab assessment={assessment} assessmentMode={assessmentMode} />
        ) : (
          assessment && (
            <UpdateTab
              assessment={assessment}
              assessmentMode={assessmentMode}
              showAssessmentSelector={showAssessmentSelector}
              navigateToResults={navigateToResults}
            />
          )
        ),
    });
  }

  return filterEmptyTabs(tabs);
};

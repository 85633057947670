import { Icon } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { User01, Users01 } from '@untitled-ui/icons-react';
import { useEffect, useRef, useState } from 'react';
import stc from 'string-to-color';

import { getAccessibleTextColor } from '@/utils/colours';

import { TextareaInput } from '../Form/ControlledTextarea/ControlledTextarea';
import styles from './style.module.scss';

type UserPreviewProps = {
  user?: {
    FriendlyName?: string | null;
    Email?: string | null;
  } | null;
  group?: {
    Name?: string | null;
  } | null;
  owner?: boolean;
  response?: boolean | null;
  updating?: boolean;
  submitResponse?: (response: boolean, comment: string) => Promise<void>;
  showApproveReject?: boolean;
  comment?: string | null;
  hasPermissionToOverride?: boolean;
};

export const UserPreview = ({
  user,
  group,
  owner,
  response,
  updating,
  submitResponse,
  showApproveReject,
  comment,
}: UserPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [activeComment, setActiveComment] = useState<string>('');

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    try {
      if (user || group) {
        const generatedColor = stc(user?.Email ?? group?.Name);
        ref.current.style.backgroundColor = generatedColor;
        ref.current.style.color = getAccessibleTextColor(
          generatedColor ?? '#000000'
        );
      } else if (owner) {
        const color = '#444';
        ref.current.style.backgroundColor = color;
        ref.current.style.color = getAccessibleTextColor(color);
      }
    } catch (e) {
      console.error(e);
      ref.current.style.backgroundColor = '#000000';
      ref.current.style.color = '#FFFFFF';
    }
  }, [user, ref, owner, group]);

  return (
    <div className="p-4 border-2 border-solid border-grey150 bg-off_white rounded-md ">
      <div className={'flex items-center justify-between'}>
        <div className={'flex gap-4 items-center'}>
          <div
            ref={ref}
            className={`w-9 h-9 rounded-full grid place-items-center font-bold text-white text-lg`}
            data-testid={'user-preview-icon'}
          >
            {user ? (
              <span className={'opacity-80'}>
                {(user.FriendlyName || '🤷')[0].toUpperCase()}
              </span>
            ) : null}
            {owner ? <User01 data-testid="owner-icon" /> : null}
            {group ? <Users01 data-testid="group-icon" /> : null}
          </div>
          <div className={'flex gap-1 flex-col'}>
            {/* Tailwind max-w-48 doesnt work for some reason */}
            <h4 className={'!m-0 truncate'} style={{ maxWidth: '192px' }}>
              {owner ? 'Owner' : group?.Name || user?.FriendlyName}
            </h4>
            {owner || user?.Email || group ? (
              <p className={'!m-0 text-xs'}>
                {owner
                  ? 'Owner approval required'
                  : group
                    ? 'Any user from this group must approve'
                    : user?.Email ?? 'No email'}
              </p>
            ) : null}
          </div>
        </div>
        <div>
          {response === null ? <Icon name="status-pending" /> : null}
          {response === true ? <Icon name="status-positive" /> : null}
          {response === false ? <Icon name="status-negative" /> : null}
        </div>
      </div>
      {(comment || showApproveReject) && (
        <div className="bg-off_white pt-4">
          {comment && (
            <>
              <h4 className="text-grey !text-xs">Comments:</h4>
              <p className="p-4 !m-0 border-2 border-solid border-grey150 ">
                {comment}
              </p>
            </>
          )}
          {showApproveReject && (
            <>
              <TextareaInput
                label="Comment"
                value={activeComment ?? ''}
                onChange={(val) => {
                  setActiveComment?.(val);
                }}
                inferenceDisabled={true}
              />
              <div className={'flex gap-3'}>
                <Button
                  loading={updating}
                  onClick={async (e) => {
                    e.preventDefault();
                    await submitResponse?.(true, activeComment);
                  }}
                  variant={'primary'}
                >
                  Approve
                </Button>
                <Button
                  loading={updating}
                  onClick={async (e) => {
                    e.preventDefault();
                    await submitResponse?.(false, activeComment);
                  }}
                  {...{ className: styles.dangerButton }}
                >
                  Reject
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

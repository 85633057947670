import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionUpdateModal from 'src/pages/action-updates/ActionUpdateModal';
import { Permission, useHasPermission } from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  GetActionByIdQuery,
  useDeleteActionUpdatesMutation,
  useGetActionUpdatesByParentActionIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { ActionUpdateTableFields, useUpdateColumnDefinitions } from './config';

interface Props {
  action: GetActionByIdQuery['action'][number];
}
const Tab: FC<Props> = ({ action }) => {
  useI18NSummaryHelpContent('actionUpdates.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actionUpdates',
  });
  const canDeleteActionUpdate = useHasPermission(
    'delete:action_update',
    action
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const actionId = useGetGuidParam('actionId');

  const { addNotification } = useNotifications();
  const [selectedActionUpdates, setSelectedActionUpdates] = useState<
    ActionUpdateTableFields[]
  >([]);

  const [deleteActionUpdates, deleteResult] = useDeleteActionUpdatesMutation({
    update: (cache) => evictField(cache, 'action_update'),
  });

  const [actionUpdateId, setActionUpdateId] = useState<string | undefined>();

  const { data, loading, refetch } = useGetActionUpdatesByParentActionIdQuery({
    variables: {
      _eq: actionId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleActionUpdateModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleActionUpdateModalClose = () => {
    setActionUpdateId(undefined);
    setIsEditOpen(false);
    refetch();
  };

  const updateColumnDefinitions = useUpdateColumnDefinitions((actionUpdate) => {
    setActionUpdateId(actionUpdate.Id);
    setIsEditOpen(true);
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteActionUpdates({
        variables: {
          Ids: selectedActionUpdates.map((s) => s.Id),
        },
      });
      setSelectedActionUpdates([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const labelledFields = useMemo<ActionUpdateTableFields[]>(() => {
    return (
      data?.action_update.map((r) => ({
        ...r,
        CreatedByUserName: r.createdByUser?.FriendlyName ?? null,
      })) || []
    );
  }, [data?.action_update]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:action_update">
              <Button formAction="none" onClick={handleActionUpdateModalOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        // Sort by most recent update desc
        sortingColumn: updateColumnDefinitions[3],
        isDescending: true,
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteActionUpdate ? 'multi' : undefined}
        selectedItems={selectedActionUpdates}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedActionUpdates(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:action_update"
                    parentObject={action}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedActionUpdates.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:action_update"
                    parentObject={action}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleActionUpdateModalOpen}
                    >
                      {st('add_button')}
                    </Button>{' '}
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={updateColumnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isEditOpen && (
        <ActionUpdateModal
          actionUpdateId={actionUpdateId}
          action={action}
          onDismiss={handleActionUpdateModalClose}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setSelectedActionUpdates([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;

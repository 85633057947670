import {
  CollectionActions,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks/cjs/interfaces';
import {
  Pagination,
  PaginationProps,
  PropertyFilterProps,
  TableProps,
} from '@cloudscape-design/components-themed';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import i18next from '@risksmart-app/components/providers/i18n';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import dayjs from 'dayjs';
import { ParseKeys } from 'i18next/typescript/t';
import { useTranslation } from 'react-i18next';
import { arrayToCsv, downloadBlob } from 'src/data/rest/fileUtils';

import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import { handleError } from '@/utils/errorUtils';
import { recordsToExportArray } from '@/utils/table/utils/tableExport';
import { labelWithPlural } from '@/utils/utils';

import CollectionPreferences from '../components/CollectionPreferences';
import TableFooter from '../components/TableFooter';
import {
  TableFields,
  TablePreferences,
  TablePropsWithActions,
  TableRecord,
  TableRecordColumnWidths,
} from '../types';

type UseBuildTablePropsConfig<T extends TableRecord> = {
  tableFields: TableFields<T>;
  allPageItems: readonly (T & unknown)[];

  entityLabel: ParseKeys<'common'>;
  columnDefinitions: TableProps.ColumnDefinition<T>[];
  items: readonly (T & unknown)[];
  allItems: readonly (T & unknown)[];
  filtersEnabled: boolean;
  propertyFilterProps: PropertyFilterProps;
  paginationProps: PaginationProps;
  actions: CollectionActions<T>;
  preferences: TablePreferences<T>;
  setPreferences: (preferences: TablePreferences<T>) => void;
  onSortingChange: (sortingState: TableProps.SortingState<T>) => void;
  onPropertyFilterChange: (query: PropertyFilterQuery) => void;
  extraFilters?: React.ReactNode;
  extraProps: Partial<TablePropsWithActions<T>>;
};

export const useBuildTableProps = <T extends TableRecord>({
  tableFields,
  allPageItems,
  preferences,
  entityLabel,
  columnDefinitions,
  items,
  allItems,
  propertyFilterProps,
  filtersEnabled,
  paginationProps,
  actions,
  setPreferences,
  onSortingChange,
  onPropertyFilterChange,
  extraProps,
  extraFilters,
}: UseBuildTablePropsConfig<T>): TablePropsWithActions<T> => {
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common']);

  const props: TablePropsWithActions<T> = {
    ...extraProps,
    propertyFilterProps,
    exportToCsvString: () => {
      try {
        const data = recordsToExportArray(
          allPageItems,
          tableFields,
          preferences?.contentDisplay?.filter((c) => c.visible).map((c) => c.id)
        );

        return arrayToCsv(data);
      } catch (e) {
        handleError(e);
        addNotification({
          type: 'error',
          content: t('export_failed_message'),
        });

        return '';
      }
    },
    exportToCsv: () => {
      try {
        // '\ufeff' is the utc-8 BOM so characters appear correctly in excel
        const blob = new Blob(['\ufeff', props.exportToCsvString()], {
          type: 'text/csv;charset=utf-8',
        });

        downloadBlob(`${entityLabel}-${dayjs().toISOString()}.csv`, blob);
      } catch (e) {
        handleError(e);
        addNotification({
          type: 'error',
          content: t('export_failed_message'),
        });
      }
    },
    items,
    columnDefinitions,
    visibleColumns: preferences?.visibleContent,
    filter: filtersEnabled && (
      <div className={'flex flex-row gap-3'}>
        <PropertyFilterPanel
          i18nStrings={{
            ...defaultPropertyFilterI18nStrings,
            filteringPlaceholder: i18next.t(
              propertyFilterProps.disableFreeTextFiltering
                ? 'tables.filtering_placeholder'
                : 'tables.filtering_placeholder_free_text',
              {
                entity: labelWithPlural(entityLabel).plural,
              }
            ),
          }}
          expandToViewport={true}
          virtualScroll={true}
          {...propertyFilterProps}
        />
        {extraFilters}
      </div>
    ),
    preferences: (
      <CollectionPreferences
        preferences={preferences}
        setPreferences={setPreferences}
        fields={tableFields}
        entityLabel={entityLabel}
      />
    ),
    onSortingChange: (e) => onSortingChange(e.detail),
    pagination: <Pagination {...paginationProps} />,
    actions: {
      ...actions,
      setPropertyFiltering: onPropertyFilterChange,
    },
    propertyFilterQuery: propertyFilterProps.query,
    resizableColumns: true,
    sortingDisabled: false,
    loadingText: i18next.t('tables.loading_message', {
      entity: labelWithPlural(entityLabel).plural,
    }),
    filteringProperties: propertyFilterProps.filteringProperties,
    allItems: allItems,
    stickyColumns: preferences?.stickyColumns,
    wrapLines: preferences?.wrapLines,
    stripedRows: preferences?.stripedRows,
    columnDisplay: preferences?.contentDisplay,
    contentDensity: preferences?.contentDensity,
    footer: (
      <TableFooter
        tableFields={tableFields}
        items={items}
        tablePreferences={preferences}
      />
    ),
    onColumnWidthsChange: (e) => {
      setPreferences({
        ...preferences,
        custom: {
          columnWidths: Object.keys(tableFields).reduce(
            (previous, current, i) => {
              return {
                ...previous,
                [current]: e.detail.widths[i],
              };
            },
            {} as TableRecordColumnWidths<T>
          ),
        },
      });
    },
    preferenceDetails: {
      preferences,
      setPreferences,
    },
  };

  return props;
};

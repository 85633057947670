import { TableProps } from '@cloudscape-design/components-themed';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  conformanceIndicatorRating,
  getConformanceTrendRating,
} from 'src/pages/indicators/calculateConformanceRating';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  GetIndicatorByIdQuery,
  GetIndicatorResultsByIndicatorIdQuery,
  Indicator_Type_Enum,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';

export type IndicatorResultFields =
  GetIndicatorResultsByIndicatorIdQuery['indicator_result'][number] & {
    previous?: GetIndicatorResultsByIndicatorIdQuery['indicator_result'][number];
  };

export function useResultColumnDefinitions(
  indicator: GetIndicatorByIdQuery['indicator'][number],
  onEdit: (impact: IndicatorResultFields) => void
) {
  const { getByValue: statusGetByValue } = useRating(
    'indicator_conformance_status'
  );
  const { getByValue: trendGetByValue } = useRating(
    'indicator_conformance_trend'
  );
  const { t } = useTranslation(['common'], {
    keyPrefix: 'indicator_results.columns',
  });
  const impactColumnDefinitions: TableProps<IndicatorResultFields>['columnDefinitions'] =
    [
      {
        id: 'date',
        header: t('date_time'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit(item)}>
            {toLocalDate(item.ResultDate)}
          </Link>
        ),
        sortingField: 'ResultDate',
        isRowHeader: true,
      },
      {
        id: 'description',
        header: t('description'),
        cell: (item) => item.Description || '-',
      },
      {
        id: 'modifiedByUser',
        header: t('modified_by'),
        cell: (item) => item.modifiedBy?.FriendlyName || '-',
      },
      {
        id: 'result',
        header: t('result'),
        cell: (item) => getResult(item),
      },
      {
        id: 'conformance',
        header: t('conformance'),
        cell: (item) => {
          const rating = conformanceIndicatorRating(indicator, item);

          return <SimpleRatingBadge rating={statusGetByValue(rating)} />;
        },
      },
      {
        id: 'conformanceTrend',
        header: t('conformance_trend'),
        cell: (item) => {
          const results = [item];
          if (item.previous) {
            results.push(item.previous);
          }
          const rating = trendGetByValue(
            getConformanceTrendRating(indicator, results)
          );

          return <SimpleRatingBadge rating={rating} />;
        },
      },
    ];

  return impactColumnDefinitions;
}

export const getResult = (item: {
  TargetValueTxt?: string | null;
  TargetValueNum?: number | null;
  parent?: {
    Type: Indicator_Type_Enum;
  } | null;
}): string => {
  let result = '-';
  if (
    item.parent?.Type === Indicator_Type_Enum.Number &&
    !_.isNil(item.TargetValueNum)
  ) {
    result = `${item.TargetValueNum}`;
  }
  if (item.parent?.Type === Indicator_Type_Enum.Text && item.TargetValueTxt) {
    result = item.TargetValueTxt;
  }

  return result;
};

import { TableProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Questionnaire_Template_Version_Status_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

export type QuestionnaireTemplateVersionTableFields = {
  version: string;
  status: Questionnaire_Template_Version_Status_Enum;
  id: string;
  createdAtTimestamp: string;
};

export const useQuestionnaireTemplateVersionColumnDefinitions = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions.columns',
  });

  const getStatus = useRating('questionnaire_template_version_status');

  const columnDefinitions: TableProps<QuestionnaireTemplateVersionTableFields>['columnDefinitions'] =
    [
      {
        id: 'version',
        header: st('version'),
        cell: (item) => (
          <Link variant="secondary" href={`update/${item.id}`} isRelativeUrl>
            {item.version}
          </Link>
        ),
        sortingField: 'version',
        isRowHeader: true,
      },
      {
        id: 'status',
        header: st('status'),
        cell: (item) => (
          <SimpleRatingBadge rating={getStatus.getByValue(item.status)} />
        ),
        sortingField: 'status',
      },
    ];

  return columnDefinitions;
};

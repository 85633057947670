import { OptionDefinition } from '@cloudscape-design/components-themed/internal/components/option/interfaces';
import { FormField } from '@risksmart-app/web/src/components/Form/Form/FormField';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import HelpLink from '@/components/HelpPanel/HelpLink';
import Tokens from '@/components/Tokens';
import { Department } from '@/generated/graphql';
import { DepartmentType } from '@/types/index';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import { getSelectedOptions } from '../form-utils';
import Multiselect from '../MultiSelect';
import { ControlledBaseProps } from '../types';
import styles from './style.module.scss';
import { useDepartmentOptions } from './useDepartmentOptions';

interface Props<T extends FieldValues = FieldValues>
  extends Omit<ControlledBaseProps<T>, 'label'> {
  control: Control<T>;
  label?: string;
  disabled?: boolean;
  hiddenTokens?: boolean;
}

export const DepartmentSelector = <T extends FieldValues = FieldValues>({
  control,
  label,
  name,
  placeholder,
  disabled,
  forceRequired,
  hiddenTokens,
  defaultRequired,
  allowDefaultValue,
  description,
  ...props
}: Props<T>) => {
  const { t } = useTranslation();
  const { departments, optionItems: options } = useDepartmentOptions();
  label = label ?? t('fields.Departments');
  description = description ?? t('fields.Departments_help');
  const { setValue } = useFormContext();

  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  const updateDepartments = (selectedOptions: readonly OptionDefinition[]) => {
    const selectedIds = selectedOptions.map((option) => option.value!);
    setValue('DepartmentTypeIds', selectedIds);
  };

  return (
    <Controller
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      name={name}
      control={control}
      render={({ field: { ref, onChange, onBlur, value } }) => {
        const fieldValuesAsOptions = convertDepartmentsToOptions(
          value || [],
          departments
        );
        const selectedOptions = getSelectedOptions(
          fieldValuesAsOptions,
          options
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          updateDepartments(selectedOptions);
        }, [selectedOptions]);

        const selectedValues: DepartmentType[] | undefined = value;
        const removeToken = (itemValue: string) => {
          onChange(
            (selectedValues ?? []).filter(
              (v) => v.DepartmentTypeId !== itemValue
            )
          );
        };

        return (
          <div className={styles.root}>
            <FormField
              label={label}
              errorText={error?.message}
              stretch
              info={
                description && (
                  <HelpLink id={label} title={label} content={description} />
                )
              }
              hasFieldChanged={(val) => {
                if (!val) {
                  return false;
                }

                return !isEqual(
                  val.from?.map((f: Department) => ({
                    ParentId: f.ParentId,
                    DepartmentTypeId: f.DepartmentTypeId,
                  })),
                  val.to
                );
              }}
              previewChangesFormatter={(
                val: [{ DepartmentTypeId: string }] | undefined | null
              ) => {
                if (Array.isArray(val)) {
                  return val
                    .map((v) => v.DepartmentTypeId)
                    .map(
                      (v) =>
                        departments?.find((d) => d.DepartmentTypeId === v)?.Name
                    )
                    .join(', ');
                }

                return '-';
              }}
            >
              <Multiselect
                ref={ref}
                options={options}
                selectedOptions={selectedOptions}
                onBlur={onBlur}
                filteringType="auto"
                onChange={(e) => {
                  onChange(
                    convertOptionsToDepartmentTypes(e.detail.selectedOptions)
                  );
                }}
                placeholder={placeholder ?? t('select')}
                empty={t('noMatchedFound')}
                disabled={disabled || readOnly}
                {...props}
                hideTokens
              />
              {!hiddenTokens && (
                <Tokens
                  disabled={disabled || readOnly}
                  onRemove={removeToken}
                  tokens={selectedOptions.map((o) => ({
                    value: o.value!,
                    label: o.label!,
                  }))}
                />
              )}
            </FormField>
          </div>
        );
      }}
    />
  );
};

/*
  Converts a single department option definition back to a department type
*/
const convertOptionToDepartmentType = (
  option: OptionDefinition
): DepartmentType => ({
  Name: option.label || '',
  Description: option.description || '',
  DepartmentTypeId: option.value || '',
});

/*
  Converts an array of option definitions back to department types
*/
const convertOptionsToDepartmentTypes = (
  options: readonly OptionDefinition[]
): DepartmentType | Array<DepartmentType> =>
  options.map((option) => convertOptionToDepartmentType(option));

/*
  Converts a single department type to a single department option definition
*/
const convertDepartmentTypeToOption = (
  department: DepartmentType
): OptionDefinition => ({
  label: department.Name || '',
  description: department.Description || '',
  value: department.DepartmentTypeId,
});

function convertDepartmentsToOptions(
  input: DepartmentType | Array<DepartmentType>,
  departmentTypes: Array<DepartmentType>
): OptionDefinition[] {
  const departments = Array.isArray(input) ? input : [input];

  return departments.map((department) => {
    const departmentType = departmentTypes.find(
      ({ DepartmentTypeId }) => DepartmentTypeId === department.DepartmentTypeId
    );
    if (!departmentType) {
      return {};
    }

    return convertDepartmentTypeToOption(departmentType);
  });
}

import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import { Parent_Type_Enum } from '@/generated/graphql';

import {
  ObjectWithContributors,
  useHasPermission,
} from '../../../../rbac/Permission';
import { useGetApprovalTableProps } from './config';
import ApprovalModal from './modals/ApprovalModal';

type Props = {
  parent?: ObjectWithContributors;
  approvalType?: Parent_Type_Enum;
};

/**
 * Tab for displaying approval
 *
 * @param parent - The object which the approval process is placed on
 * @param approvalType - The object which the approvals are configured from
 *   (defaults to parentType if not provided)
 */
export const ApprovalsTab: FC<Props> = ({ parent, approvalType }) => {
  useI18NSummaryHelpContent(
    parent ? 'approvals.objectLevelHelp' : 'approvals.help'
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [updateApprovalId, setUpdateApprovalId] = useState<string | null>(null);
  const { t } = useTranslation(['common'], { keyPrefix: 'approvals' });

  const tableProps = useGetApprovalTableProps(parent, (item) => {
    setUpdateApprovalId(item.Id);
    setModalOpen(true);
  });

  const hasPermission =
    useHasPermission(`update:${approvalType!}`, parent) || !approvalType;

  useEffect(() => {
    if (!modalOpen) {
      setUpdateApprovalId(null);
    }
  }, [modalOpen]);

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    formAction="none"
                    onClick={() => {
                      setModalOpen(true);
                      setUpdateApprovalId(null);
                    }}
                    disabled={!hasPermission}
                  >
                    {t('add_approval')}
                  </Button>
                </SpaceBetween>
              }
            >
              {t('approvalTableTitle')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
      />
      <ApprovalModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        approvalId={updateApprovalId}
        parentId={parent?.Id}
        readOnly={!hasPermission}
      />
    </>
  );
};

import ExpandableSection from '@cloudscape-design/components/expandable-section';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  Parent_Type_Enum,
  useDeleteAssessmentResultsMutation,
  useGetRiskAssessmentResultsByRiskIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getCounter } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from '../../../../../rbac/Permission';
import AssessmentResultModal from '../../../../assessments/modals/AssessmentResultModal';
import { useGetCollectionTableProps } from './riskRatingConfig';
import { RiskAssessmentResultRegisterFields } from './types';

interface Props {
  risk: ObjectWithContributors;
}

const RiskRatingRegister: FC<Props> = ({ risk }) => {
  useI18NSummaryHelpContent('assessments.tabHelp');
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'ratings' });
  const { data, loading, refetch } = useGetRiskAssessmentResultsByRiskIdQuery({
    variables: {
      RiskId: risk.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [selectedAssessmentResults, setSelectedAssessmentResults] = useState<
    RiskAssessmentResultRegisterFields[]
  >([]);

  const [openAssessmentResultId, setOpenAssessmentResultId] = useState<
    string | undefined
  >();

  const userCanDeleteAssessmentResults = useHasPermission(
    'delete:risk_assessment_result',
    risk
  );

  const [deleteAssessmentResults, deleteResult] =
    useDeleteAssessmentResultsMutation({
      update: (cache) => {
        evictField(cache, 'risk_assessment_result');
        evictField(cache, 'assessment');
        evictField(cache, 'risk_assessment_result_aggregate');
        evictField(cache, 'risk_score');
      },
    });

  const handleAssessmentResultModalClose = () => {
    setOpenAssessmentResultId(undefined);
    setIsEditOpen(false);
  };

  const onAddRating = () => {
    setIsEditOpen(true);
  };

  const onOpenResult = (id: string) => {
    setOpenAssessmentResultId(id);
    setIsEditOpen(true);
  };
  const onDelete = useDeleteResultNotification({
    entityName: t('assessmentResults.entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteAssessmentResults({
        variables: {
          Ids: selectedAssessmentResults.map(
            (assessmentResult) => assessmentResult.Id
          ),
        },
      });
      setSelectedAssessmentResults([]);
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  const tableProps = useGetCollectionTableProps(
    risk,
    onOpenResult,
    onAddRating,
    data?.risk_assessment_result,
    data?.form_configuration?.flatMap((f) =>
      f && f.customAttributeSchema ? [f.customAttributeSchema] : []
    ) ?? []
  );

  return (
    <>
      <SpaceBetween size="m">
        <TabHeader
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Permission
                permission="delete:risk_assessment_result"
                parentObject={risk}
              >
                <Button
                  formAction="none"
                  variant="normal"
                  disabled={
                    !selectedAssessmentResults.length ||
                    !userCanDeleteAssessmentResults
                  }
                  onClick={() => setIsDeleteModalVisible(true)}
                >
                  {t('assessmentResults.delete_button')}
                </Button>
              </Permission>
              <Permission
                permission="insert:risk_assessment_result"
                parentObject={risk}
              >
                <Button
                  iconName="add-plus"
                  variant="primary"
                  formAction="none"
                  onClick={onAddRating}
                >
                  {t('assessments.add_rating_button')}
                </Button>
              </Permission>
            </SpaceBetween>
          }
        >
          {st('tab_title')}
        </TabHeader>
      </SpaceBetween>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{st('riskRatingSubheading')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <Table
          {...tableProps}
          selectionType="multi"
          selectedItems={selectedAssessmentResults}
          trackBy="Id"
          onSelectionChange={({ detail }) => {
            setSelectedAssessmentResults(detail.selectedItems);
          }}
          resizableColumns={true}
          variant="embedded"
          loading={loading}
          data-testid="risk-rating-table"
        />
      </ExpandableSection>
      {isEditOpen && (
        <AssessmentResultModal
          id={openAssessmentResultId}
          onDismiss={handleAssessmentResultModalClose}
          assessedItem={risk}
          resultType={Parent_Type_Enum.RiskAssessmentResult}
          hideTypeSelector={true}
          i18n={t('ratings')}
          assessmentMode={'rating'}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setOpenAssessmentResultId(undefined);
          setSelectedAssessmentResults([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};
export default RiskRatingRegister;

import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import ActionsTab from 'src/pages/actions/ActionsTab';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/Permission';

import { GetRiskByIdQuery, Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import ControlsTab from '../../controls/tab/Tab';
import IndicatorTab from '../../indicators/tab/Tab';
import { ApprovalsTab } from '../../settings/tabs/approvals/Tab';
import RiskCreateTab from '../create/tabs/RiskCreateTab';
import AcceptancesTab from './tabs/acceptances/Tab';
import AppetitesTab from './tabs/appetites/Tab';
import AssessmentTab from './tabs/assessments/Tab';
import DetailsTab from './tabs/details/Tab';
import ImpactsTab from './tabs/impacts/Tab';

export const useTabs = (
  risk: GetRiskByIdQuery['risk'][number] | undefined = undefined
) => {
  const { t } = useTranslation([]);
  const impactedEnabled = useIsFeatureVisibleToOrg('impacts');
  const canViewActions = useHasPermission('read:action', risk);
  const canViewAcceptances = useHasPermission('read:acceptance', risk);
  const canViewControls = useHasPermission('read:control', risk);
  const canViewAppetites = useHasPermission('read:appetite', risk);
  const canViewAssessments = useHasPermission('read:assessment', risk);
  const canViewIndicators = useHasPermission('read:indicator', risk);
  const canViewImpactRatings = useHasPermission('read:impact_rating', risk);
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', risk);
  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');
  const detailsOnly = !risk;

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: detailsOnly ? <RiskCreateTab /> : <DetailsTab />,
      href: detailsOnly ? undefined : `/risks/${risk.Id}`,
    },
  ];

  if (canViewControls) {
    tabs.push({
      label: t('controls.tab_title'),
      id: 'controls',
      content: risk && <ControlsTab parent={risk} />,
      href: `/risks/${risk?.Id}/controls`,
      disabled: detailsOnly,
    });
  }

  if (canViewAssessments && !impactedEnabled) {
    tabs.push({
      label: t('ratings.tab_title'),
      id: 'ratings',
      content: risk && <AssessmentTab risk={risk} />,
      href: `/risks/${risk?.Id}/ratings`,
      disabled: detailsOnly,
    });
  }

  if (impactedEnabled && canViewImpactRatings) {
    tabs.push({
      label: t('impacts.tab_title'),
      id: 'impacts',
      content: risk && <ImpactsTab parent={risk} />,
      href: `/risks/${risk?.Id}/impacts`,
      disabled: detailsOnly,
    });
  }

  if (canViewAppetites) {
    tabs.push({
      label: t('appetites.tab_title'),
      id: 'appetites',
      content: risk && <AppetitesTab parent={risk} />,
      href: `/risks/${risk?.Id}/appetite`,
      disabled: detailsOnly,
    });
  }

  if (canViewAcceptances) {
    tabs.push({
      label: t('acceptances.tab_title'),
      id: 'acceptances',
      content: risk && <AcceptancesTab parent={risk} />,
      href: `/risks/${risk?.Id}/acceptances`,
      disabled: detailsOnly,
    });
  }

  if (canViewActions) {
    tabs.push({
      label: t('actions.tab_title'),
      id: 'actions',
      content: risk && <ActionsTab parent={risk} />,
      href: `/risks/${risk?.Id}/actions`,
      disabled: detailsOnly,
    });
  }

  if (canViewIndicators) {
    tabs.push({
      label: t('indicators.tab_title'),
      id: 'indicators',
      content: risk && <IndicatorTab parent={risk} />,
      href: `/risks/${risk?.Id}/indicators`,
      disabled: detailsOnly,
    });
  }

  if (approvalsEnabled) {
    tabs.push({
      label: t('approvals.tab_title'),
      id: 'approvals',
      content: risk && <ApprovalsTab parent={risk} approvalType={'risk'} />,
      href: `/risks/${risk?.Id}/approvals`,
      disabled: detailsOnly,
    });
  }

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: risk && (
        <LinkedItemsTab parent={risk} parentType={Parent_Type_Enum.Risk} />
      ),
      href: `/risks/${risk?.Id}/linked-items`,
      disabled: detailsOnly,
    });
  }

  return filterEmptyTabs(tabs);
};

import Button from '@risksmart-app/components/Button';
import parse from 'html-react-parser';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import { downloadBlob } from 'src/data/rest/fileUtils';

import Loading from '@/components/Loading';

import { TextContent } from '../../../../theme/risksmart-themed/components';
import { PublicDocumentData } from '../../pages/files/config';

type Props = {
  data: PublicDocumentData;
};

export const PolicyDocumentPreview = ({ data }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common', { keyPrefix: 'policy' });

  const pdfLink = useMemo(() => {
    if (data.type !== 'file' || !data.blob) {
      return;
    }

    return URL.createObjectURL(data.blob);
  }, [data]);

  const previewBody = () => {
    switch (data.type) {
      case 'html':
        return (
          <TextContent>
            {parse(sanitizeHtml(data.documentFile.Content ?? ''))}
          </TextContent>
        );
      case 'file':
        return !data.blob ? (
          <Loading />
        ) : data.documentFile.file?.FileName.toLowerCase()
            .trim()
            .endsWith('.pdf') ? (
          <div data-testid={'pdf-viewer'}>
            <iframe className={'w-full min-h-[800px]'} src={pdfLink} />
          </div>
        ) : (
          <div
            className={
              'flex justify-center items-center flex-col text-center mb-4'
            }
          >
            <h2 className={'mb-2'}>{t('publicFile.title')}</h2>
            <p>{t('publicFile.body')}</p>
            <Button
              onClick={() =>
                data.blob &&
                downloadBlob(
                  data.documentFile.file?.FileName ?? 'file',
                  data.blob
                )
              }
            >
              {t('publicFile.button')}
            </Button>
          </div>
        );
      case 'link':
        return (
          <div
            className={
              'flex justify-center items-center flex-col text-center mb-4'
            }
          >
            <h2 className={'mb-2'}>{t('publicLink.title')}</h2>
            <p>{t('publicLink.body')}</p>
            <Button onClick={() => window.open(data.link, '_blank')}>
              {t('publicLink.button')}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return <div ref={containerRef}>{previewBody()}</div>;
};

import { PropertyFilterOperator } from '@cloudscape-design/collection-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CommaSeparatedCell from '@/components/CommaSeparatedCell';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  Approval_Status_Enum,
  GetChangeRequestsQuery,
} from '@/generated/graphql';
import { Parent_Type_Enum } from '@/generated/graphql.typed';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';
import { useChangeRequests } from '@/hooks/useChangeRequests';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetApproversFieldConfig } from '@/utils/table/hooks/useGetApproversFieldConfig';
import { useGetCurrentApproversFieldConfig } from '@/utils/table/hooks/useGetCurrentApproversFieldConfig';
import { useGetNextApproversFieldConfig } from '@/utils/table/hooks/useGetNextApproversFieldConfig';
import { useGetRequestersFieldConfig } from '@/utils/table/hooks/useGetRequestersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { policyFileDetailsUrl } from '@/utils/urls';

import { ChangeRequestRegisterFields } from './types';

const useGetChangeRequestParentUrl = () => {
  const navigate = useNavigate();
  const getEntityInfo = useEntityInfo();
  const gotoParentUrl = async (changeRequest: ChangeRequestRegisterFields) => {
    let url = '';
    if (changeRequest.parent?.ObjectType) {
      if (changeRequest.parent.ObjectType === 'document_file') {
        if (changeRequest.parent.documentFile?.parent) {
          url = policyFileDetailsUrl(
            changeRequest.parent.documentFile.parent.Id,
            changeRequest.parent.Id
          );
        }
      } else if (changeRequest.parent.ObjectType === 'issue_assessment') {
        const entityInfo = getEntityInfo(changeRequest.parent.ObjectType);
        url = entityInfo.url(
          changeRequest.parent.issue_assessment?.parent?.Id ?? '#'
        );
      } else {
        const entityInfo = getEntityInfo(changeRequest.parent.ObjectType);
        url = entityInfo.url(changeRequest.parent.Id);
      }
    }

    navigate(`${url}?showRequest=true&requestId=${changeRequest.Id}`);
  };

  return gotoParentUrl;
};

const useRequestsFields = () => {
  const { t } = useTranslation(['common']);
  const { getByValue } = useRating('approval_status');
  const gotoParentUrl = useGetChangeRequestParentUrl();
  const approversField = useGetApproversFieldConfig();
  const requestersField = useGetRequestersFieldConfig();
  const currentApproversField = useGetCurrentApproversFieldConfig();
  const nextApproversField = useGetNextApproversFieldConfig();

  const fields: TableFields<ChangeRequestRegisterFields> = {
    SequentialId: {
      id: 'sequentialId',
      header: t('columns.id'),
      cell: (item) =>
        getFriendlyId(Parent_Type_Enum.ChangeRequest, item.SequentialId),
      exportVal: (item) =>
        getFriendlyId(Parent_Type_Enum.ChangeRequest, item.SequentialId),
    },
    ParentSequentialId: {
      id: 'parentSeqId',
      header: t('columns.parentId'),
    },
    ParentName: {
      id: 'parentName',
      header: t('columns.parentName'),
      cell: (item) =>
        item.parent ? (
          <Link
            variant="secondary"
            href={'#'}
            onFollow={() => gotoParentUrl(item)}
          >
            {item.ParentName}
          </Link>
        ) : (
          item.ParentName
        ),
    },
    ParentType: {
      id: 'parentType',
      header: t('columns.parentType'),
    },
    allApprovers: approversField,
    allRequesters: requestersField,
    currentApprovers: currentApproversField,
    nextApprovers: nextApproversField,
    RequiresAction: {
      id: 'requiresAction',
      header: 'Requires Action',
      cell: (item) => (item.RequiresAction ? 'Yes' : 'No'),
    },
    StatusLabelled: {
      id: 'status',
      header: t('approvals.requestsRegister.columns.status'),
      cell: (item) => (
        <SimpleRatingBadge rating={getByValue(item.ChangeRequestStatus)} />
      ),
    },

    ParentId: {
      id: 'ParentId',
      header: t('approvals.requestsRegister.columns.parentGuid'),
    },
    Workflow: {
      id: 'workflow',
      header: t('approvals.requestsRegister.columns.workflow'),
    },
    approvalConfig: {
      id: 'approvalConfig',
      header: t('approvals.requestsRegister.columns.approvalConfig'),
      cell: (item) => (
        <CommaSeparatedCell
          items={item.approvalConfig.map((c) => ({ label: c }))}
        />
      ),
      filterOptions: {
        filteringProperties: {
          operators: (['=', ':'] as PropertyFilterOperator[]).map(
            (operator) => ({
              operator,
              match: (ids: unknown, id: string) => {
                return (ids as string[]).includes(id);
              },
            })
          ),
        },
        filteringOptions: [],
      },
    },
    CreatedAtTimestamp: dateColumn(
      t('approvals.requestsRegister.columns.dateOpened'),
      'CreatedAtTimestamp',
      undefined,
      true
    ),
    DateLastActioned: dateColumn(
      t('approvals.requestsRegister.columns.dateLastActioned'),
      'DateLastActioned',
      undefined,
      true
    ),
    DateClosed: dateColumn(
      t('approvals.requestsRegister.columns.dateClosed'),
      'DateClosed',
      undefined,
      true
    ),
    CurrentStage: {
      id: 'currentStage',
      header: t('approvals.requestsRegister.columns.currentStage'),
    },
  };

  return fields;
};

export const useRequestsTableProps = (data?: GetChangeRequestsQuery) => {
  const fields = useRequestsFields();
  const { t } = useTranslation(['taxonomy', 'common']);
  const objectTypeMapping = t('common:objectTypes', {
    returnObjects: true,
  });
  const workflowMap = t('common:approvals.workflows', {
    returnObjects: true,
  });
  const {
    isActiveApprover,
    getCurrentLevel,
    getMaxLevel,
    getCurrentApprovers,
    getNextApprovers,
  } = useChangeRequests();
  const { getByValue } = useRating('approval_status');

  const getParentName = (item: GetChangeRequestsQuery['change_request'][0]) => {
    switch (item.parent?.ObjectType) {
      case 'document_file':
        return `${item.parent?.documentFile?.parent?.Title} (${item.parent?.documentFile?.Version})`;
      case 'acceptance':
        return item.parent?.acceptance?.Title;
      case 'risk':
        return item.parent?.risk?.Title;
      case 'control':
        return item.parent?.control?.Title;
      case 'action':
        return item.parent?.action?.Title;
      case 'issue_assessment':
        return item.parent?.issue_assessment?.parent?.Title;
      default:
        return item.parent
          ? getFriendlyId(item.parent.ObjectType, item.parent.SequentialId)
          : 'Deleted Item';
    }
  };

  const labelledFields = useMemo((): ChangeRequestRegisterFields[] => {
    if (!data) {
      return [];
    }

    return data.change_request.map((cr) => ({
      ...cr,
      ParentType: cr.parent?.ObjectType
        ? objectTypeMapping[
            cr.parent?.ObjectType as keyof typeof objectTypeMapping
          ]
        : '',
      Workflow:
        workflowMap[
          cr.responses[0]?.approver?.level?.approval
            ?.Workflow as keyof typeof workflowMap
        ],
      StatusLabelled: getByValue(cr.ChangeRequestStatus)?.label ?? '-',
      ParentSequentialId: cr.parent?.ObjectType
        ? getFriendlyId(
            cr.parent.ObjectType === Parent_Type_Enum.DocumentFile
              ? Parent_Type_Enum.Document
              : cr.parent.ObjectType,
            cr.parent.ObjectType === Parent_Type_Enum.DocumentFile
              ? cr.parent?.documentFile?.parent?.SequentialId
              : cr.parent?.SequentialId
          )
        : null,
      RequiresAction: isActiveApprover(
        cr,
        cr.currentUserOwnerList?.map((u) => u.UserId ?? '')
      ),
      ParentName: getParentName(cr),
      allApprovers:
        cr.responses?.map((response, i) => ({
          id: response.approver.OwnerApprover
            ? String(i)
            : response.approver.user?.Id ?? '',
          label: response.approver.OwnerApprover
            ? 'Owner'
            : response.approver.user?.FriendlyName ?? '',
        })) ?? [],
      allRequesters: [
        {
          id: cr.createdBy?.Id ?? '',
          label: cr.createdBy?.FriendlyName ?? '',
        },
        ...cr.contributors.map((c) => ({
          id: c.user?.Id ?? '',
          label: c.user?.FriendlyName ?? '',
        })),
      ],
      approvalConfig: Array.from(
        new Set(cr.responses.map((r) => r.approver.level?.approval?.Id ?? ''))
      ),
      DateLastActioned: cr.responses
        .filter((r) => r.Approved !== null)
        .map((r) => r.ModifiedAtTimestamp)
        .sort((a, b) => b.localeCompare(a))
        .pop(),
      DateClosed:
        cr.ChangeRequestStatus !== Approval_Status_Enum.Pending
          ? cr.ModifiedAtTimestamp
          : null,
      CurrentStage: t(
        'common:approvals.requestsRegister.columns.currentStageValue',
        {
          current: getCurrentLevel(cr.responses) + 1,
          max: getMaxLevel(cr.responses) + 1,
        }
      ),
      currentApprovers: getCurrentApprovers(cr.responses),
      nextApprovers: getNextApprovers(cr.responses),
    }));
  }, [
    data,
    workflowMap,
    objectTypeMapping,
    getByValue,
    isActiveApprover,
    getCurrentLevel,
    getMaxLevel,
    getCurrentApprovers,
    getNextApprovers,
    t,
  ]);

  return useGetTableProps({
    fields,
    data: labelledFields,
    entityLabel: 'request',
    initialColumns: [
      'SequentialId',
      'ParentName',
      'Workflow',
      'StatusLabelled',
      'CreatedAtTimestamp',
      'DateLastActioned',
      'DateClosed',
    ],
    storageKey: 'RequestsRegisterTable-PreferencesV1',
  });
};

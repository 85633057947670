import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';

import { filterEmptyTabs } from '@/utils/tabUtils';

import Tab from './tabs/Tab';
export const useTabs = ({
  acceptanceId,
  parentId,
}: {
  acceptanceId?: string;
  parentId?: string;
}) => {
  const { t } = useTranslation([]);
  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: <Tab Id={acceptanceId} ParentId={parentId} />,
    },
  ];

  return filterEmptyTabs(tabs);
};

import {
  ControlProps,
  isStringControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import FormEditButton from '@risksmart-app/web/src/components/Form/Form/CustomisableForm/FormEditButton';
import { FC, useState } from 'react';

import { FormField, Input } from '../../../theme/risksmart-themed/components';
import { QuestionData } from '../../FormBuilder/form-configs/question';
import { FormQuestionModal } from '../../FormBuilder/FormQuestionModal';
import {
  CustomSchema,
  CustomUISchemaElement,
} from '../../FormBuilder/useFormBuilderContext';
import { useFormState } from '../../FormBuilder/useFormState';

export type TextCellProps = ControlProps & {
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
};

const TextCell: FC<TextCellProps> = ({
  schema,
  uischema,
  errors,
  handleChange,
  enabled,
  data,
  id,
  path,
  config,
}) => {
  const initialQuestionData: QuestionData = {
    questionTitle: uischema?.label ? `${uischema.label}` : '',
    placeholder: uischema?.options?.placeholder
      ? uischema.options.placeholder
      : '',
  };

  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const [questionData, setQuestionData] = useState(initialQuestionData);
  const { isFormDirty } = useFormState();

  const isCustomisable = schema?.isCustomisable;

  const appliedUiSchemaOptions = {
    ...config,
    ...uischema.options,
  };

  const label = uischema?.label ? `${uischema.label}` : '';

  return (
    <>
      <div className={'pb-6'}>
        <FormField
          label={label}
          stretch={true}
          errorText={isFieldDirty || isFormDirty ? errors : ''}
        >
          <div className={isCustomisable ? 'flex md:max-w-[750px]' : ''}>
            <Input
              {...{ className: 'grow' }}
              type={'text'}
              value={data || ''}
              onChange={(event) => {
                handleChange(path, event.detail.value);
                setIsFieldDirty(true);
              }}
              onBlur={() => setIsFieldDirty(true)}
              name={id}
              disabled={!enabled}
              autoFocus={appliedUiSchemaOptions.focus}
              placeholder={
                appliedUiSchemaOptions?.placeholder || 'Type text here...'
              }
            />
            {isCustomisable ? (
              <FormEditButton onClick={() => setIsEditingQuestion(true)} />
            ) : null}
          </div>
        </FormField>
      </div>

      {schema?.isCustomisable ? (
        <FormQuestionModal
          action={'edit'}
          parentId={uischema?.parentId || ''}
          questionData={questionData}
          setQuestionData={setQuestionData}
          isEditingQuestion={isEditingQuestion}
          setIsEditingQuestion={setIsEditingQuestion}
          initialQuestionData={initialQuestionData}
          uiSchema={uischema}
        />
      ) : null}
    </>
  );
};

/**
 * Default tester for text-based/string controls.
 *
 * @type {RankedTester}
 */
export const textCellTester: RankedTester = rankWith(3, isStringControl);

// @ts-ignore
export default withJsonFormsControlProps(TextCell);

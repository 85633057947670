import Button from '@risksmart-app/components/Button';
import Modal from '@risksmart-app/components/Modal';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledGroupAndUserMultiSelect from '@/components/Form/ControlledGroupAndUserMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/ControlledRadioGroup';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import { useGetUsersTargetedCountQuery } from '@/generated/graphql';
import { usePreSaveModalState } from '@/hooks/usePreSaveModalState';

import {
  Badge,
  Header,
} from '../../../../../../theme/risksmart-themed/components';
import {
  AttestationFormFieldData,
  AttestationTimeLimitSchema,
} from './documentSchema';

export const AttestationFormFields = () => {
  const { control, watch, formState } =
    useFormContext<AttestationFormFieldData>();
  const attestationForEveryone = watch('requireAttestationFromEveryone');
  const [initialUsersTargeted, setInitialUsersTargeted] = useState<
    number | null
  >(null);

  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'policy' });
  const groups = watch('attestationGroups');

  const { data } = useGetUsersTargetedCountQuery({
    variables: { groups: groups.map((g) => g.value) },
  });

  const totalUsers = useMemo(() => {
    if (!data) {
      return 0;
    }
    const targeted =
      attestationForEveryone === 'true'
        ? data.globalUsers.aggregate?.count ?? 0
        : data.groupsUsers.aggregate?.count ?? 0;
    if (initialUsersTargeted === null) {
      setInitialUsersTargeted(targeted);
    }

    return targeted;
  }, [data, attestationForEveryone, initialUsersTargeted]);

  const attestationTimeLimits = st('attestation_time_limits', {
    returnObjects: true,
  });

  const { visible, onDismiss, onConfirm } = usePreSaveModalState(
    formState.dirtyFields.attestationTimeLimit ||
      (initialUsersTargeted === 0 && totalUsers > 0)
  );

  return (
    <>
      <FieldGroup key={'attestationGroup'}>
        <div className={'flex gap-4 items-center'}>
          <Header {...{ className: '!w-fit' }} variant="h3">
            {st('headings.attestation')}
          </Header>
          <Badge color={totalUsers > 0 ? 'blue' : 'grey'}>
            {
              totalUsers > 0
                ? `${totalUsers} users targeted` /* TODO: missing translation */
                : 'Not enabled for this document' /* TODO: missing translation */
            }
          </Badge>
        </div>
        <ControlledRadioGroup
          key={'requireAttestationRadio'}
          forceRequired={true}
          items={[
            { value: 'true', label: t('booleanRadio.true') },
            { value: 'false', label: t('booleanRadio.false') },
          ]}
          transform={noTransform}
          name={'requireAttestationFromEveryone'}
          label={st('fields.AttestationTarget')}
          description={st('fields.AttestationTarget_help')}
          control={control}
        />

        <ConditionalField
          key={'attestationGroup'}
          condition={attestationForEveryone === 'false'}
        >
          <ControlledGroupAndUserMultiSelect
            includeGroups={true}
            forceRequired={true}
            userFilter={() => false}
            name={'attestationGroups'}
            label={st('fields.AttestationGroups')}
            description={st('fields.AttestationGroups_help')}
            control={control}
          />
        </ConditionalField>

        <ControlledSelect
          key={'attestationTimeLimit'}
          name={'attestationTimeLimit'}
          label={st('fields.AttestationTimeLimit')}
          description={st('fields.AttestationTimeLimit_help')}
          addEmptyOption
          options={AttestationTimeLimitSchema.options.map((option) => ({
            value: option,
            label:
              attestationTimeLimits[
                option as keyof typeof attestationTimeLimits
              ],
          }))}
          control={control}
        />

        <ControlledInput
          key={'attestationPromptText'}
          name={'attestationPromptText'}
          label={st('fields.AttestationPromptText')}
          description={st('fields.AttestationPromptText_help')}
          control={control}
        />
      </FieldGroup>

      {visible && (
        <Modal
          visible={true}
          header={
            <Header>{t('attestations.confirmConfigChangePrompt.title')}</Header>
          }
          onDismiss={onDismiss}
        >
          <p className={'mt-0'}>
            {t('attestations.confirmConfigChangePrompt.message', {
              count: totalUsers,
            })}
          </p>
          <div className="flex flex-row gap-3">
            <Button variant="primary" onClick={onConfirm}>
              {t('attestations.confirmConfigChangePrompt.confirm')}
            </Button>
            <Button onClick={onDismiss}>
              {t('attestations.confirmConfigChangePrompt.cancel')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

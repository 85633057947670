import { Box, Modal, SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type RemoveModalProps = {
  isVisible: boolean;
  onRemove: () => void;
  onDismiss: () => void;
  header?: ReactNode;
  children: ReactNode;
};

const RemovalModal: FC<RemoveModalProps> = ({
  isVisible,
  onRemove,
  onDismiss,
  header,
  children,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel={t('closeModal')}
      size="small"
      footer={
        <Box float="left">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={onRemove}>
              {t('confirmRemove')}
            </Button>
            <Button variant="link" onClick={onDismiss}>
              {t('cancel')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={header ?? t('remove')}
    >
      {children}
    </Modal>
  );
};

export default RemovalModal;

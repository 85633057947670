import { Box } from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NoWidgetData: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'dashboard' });

  return (
    <Box textAlign="center">
      <b>{t('no_data_title')}</b>
      <Box variant="p">{t('no_data_body')}</Box>
    </Box>
  );
};

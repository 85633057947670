import { Button, SpaceBetween } from '@cloudscape-design/components-themed';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal';
import TabHeader from '@/components/TabHeader';
import {
  GetAllAssessmentResultsDocument,
  GetAssessmentResultsByParentIdDocument,
  GetAssessmentResultsByParentIdQuery,
  GetDocumentAssessmentResultsByParentIdDocument,
  GetObligationAssessmentResultsByObligationIdDocument,
  GetRiskAssessmentResultsByControlTypeDocument,
  GetRiskAssessmentResultsByRiskIdDocument,
  Risk_Assessment_Result_Control_Type_Enum,
  useDeleteAssessmentResultsMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { AssessmentTypeEnum } from '../../../useAssessmentTypeConfig';
import { useGetCollectionTableProps } from './config';
import {
  AssessmentResultFields,
  AssessmentResultRegisterFields,
} from './types';

interface Props {
  loading: boolean;
  assessmentId: string;
  records: GetAssessmentResultsByParentIdQuery | undefined;
  parent: ObjectWithContributors;
  assessmentMode: AssessmentTypeEnum;
}
export const getAssessmentResultTableFields = (
  data: GetAssessmentResultsByParentIdQuery | undefined,
  assessmentId: string
): AssessmentResultFields[] | undefined => {
  return [
    ...(data?.document_assessment_result.map((a) => ({
      ...a,
      AssessmentId: assessmentId,
      typename: a.__typename!,
    })) || []),
    ...(data?.obligation_assessment_result.map((a) => ({
      ...a,
      AssessmentId: assessmentId,
      typename: a.__typename!,
    })) || []),
    ...(data?.risk_assessment_result.map((a) => ({
      ...a,
      AssessmentId: assessmentId,
      typename: a.__typename!,
    })) || []),
    ...(data?.test_result.map((a) => ({
      Id: a.Id,
      AssessmentId: assessmentId,
      Impact: 0,
      Rating: 0,
      OverallEffectiveness: a.OverallEffectiveness,
      ControlType: Risk_Assessment_Result_Control_Type_Enum.Controlled,
      NextTestDate: a.parent?.NextTestDate,
      TestDate: a.TestDate,
      parents: [],
      parent: a.parent!,
      files: a.files,
      ancestorContributors: [],
      typename: a.__typename!,
    })) || []),
  ];
};

const AssessmentRatingRegister: FC<Props> = ({
  loading,
  records,
  assessmentId,
  parent,
  assessmentMode,
}) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'assessmentResults' });
  const tableProps = useGetCollectionTableProps(
    getAssessmentResultTableFields(records, assessmentId),
    assessmentMode
  );
  const [selectedAssessmentResults, setSelectedAssessmentResults] = useState<
    AssessmentResultRegisterFields[]
  >([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const canDeleteDocumentAssessmentResult = useHasPermission(
    'delete:document_assessment_result',
    parent
  );
  const canDeleteObligationAssessmentResult = useHasPermission(
    'delete:obligation_assessment_result',
    parent
  );
  const canDeleteRiskAssessmentResult = useHasPermission(
    'delete:risk_assessment_result',
    parent
  );
  const canDeleteAssessmentResult =
    canDeleteDocumentAssessmentResult ||
    canDeleteObligationAssessmentResult ||
    canDeleteRiskAssessmentResult;

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    asyncAction: async () => {
      await deleteAssessmentResults({
        variables: {
          Ids: selectedAssessmentResults?.map((s) => s.Id),
        },
      });
      setSelectedAssessmentResults([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const [deleteAssessmentResults, deleteResult] =
    useDeleteAssessmentResultsMutation({
      update: (cache) => {
        evictField(cache, 'obligation_assessment_result');
        evictField(cache, 'document_assessment_result');
        evictField(cache, 'risk_assessment_result');
        evictField(cache, 'internal_audit_report');
        evictField(cache, 'compliance_monitoring_assessment');
        evictField(cache, 'obligation_assessment_result_aggregate');
        evictField(cache, 'document_assessment_result_aggregate');
        evictField(cache, 'risk_assessment_result_aggregate');
      },
      refetchQueries: [
        GetAssessmentResultsByParentIdDocument,
        GetDocumentAssessmentResultsByParentIdDocument,
        GetAllAssessmentResultsDocument,
        GetObligationAssessmentResultsByObligationIdDocument,
        GetRiskAssessmentResultsByControlTypeDocument,
        GetRiskAssessmentResultsByRiskIdDocument,
      ],
    });

  return (
    <>
      <Table
        {...tableProps}
        loading={loading}
        selectionType={canDeleteAssessmentResult ? 'multi' : undefined}
        selectedItems={selectedAssessmentResults}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedAssessmentResults(detail.selectedItems);
        }}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {canDeleteAssessmentResult && (
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedAssessmentResults.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete_button')}
                    </Button>
                  )}
                </SpaceBetween>
              }
            ></TabHeader>
          </SpaceBetween>
        }
        resizableColumns={true}
        variant="embedded"
        columnDefinitions={tableProps.columnDefinitions}
        items={tableProps.items}
        loadingText={t('loading_message')}
        sortingDisabled={false}
      />
      <DeleteModal
        isVisible={isDeleteModalVisible}
        loading={deleteResult.loading}
        header={t('delete_button')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {t('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default AssessmentRatingRegister;

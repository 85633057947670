import { SideNavigationProps } from '@cloudscape-design/components-themed';
import { ChevronDown, ChevronUp } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { useLocation, useMatches } from 'react-router-dom';

import { CountRenderer } from './Count';
import { Item } from './Item';
import { Icon } from './Navigation';
import SectionDropdown from './SectionDropdown';
import { SubItems } from './SubItems';

export interface SectionProps {
  item: SideNavigationProps.Section & Icon;
  renderCount?: CountRenderer;
  altTheme?: boolean;
}

export const Section = ({ item, renderCount, altTheme }: SectionProps) => {
  const matches = useMatches();
  const location = useLocation();

  const linksInSection = item.items
    .filter((item): item is SideNavigationProps.Link => 'href' in item)
    .map((item) => item.href);

  const matchesUrl =
    matches
      .map((match) => match.pathname)
      .filter((match) => {
        // `/` should only match at top level
        if (location.pathname === '/' && match === '/') {
          return true;
        }
        if (match !== '/') {
          return true;
        }
      })
      .filter((path) => linksInSection.includes(path)).length > 0;
  const [isExpanded, setIsExpanded] = useState(matchesUrl);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Item>
      <SectionDropdown onClick={onClick} match={isExpanded} icon={item.icon}>
        <span className="grow">{item.text}</span>
        {isExpanded ? (
          <ChevronUp className="ml-auto" />
        ) : (
          <ChevronDown className="ml-auto" />
        )}
      </SectionDropdown>

      {isExpanded && (
        <SubItems item={item} renderCount={renderCount} altTheme={altTheme} />
      )}
    </Item>
  );
};

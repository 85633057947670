import { Input, InputProps } from '@cloudscape-design/components-themed';
import { FormField } from '@risksmart-app/web/src/components/Form/Form/FormField';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { FieldValues, Noop, RefCallBack } from 'react-hook-form';

import HelpLink from '@/components/HelpPanel/HelpLink';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import { ControlledBaseProps } from '../types';

export interface TextInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  name?: string;
  label: string;
  stretch?: boolean;
  adornment?: ReactNode;
  errorMessage?: string;
  innerRef?: RefCallBack;
  onBlur?: Noop;
  onChange: (value: string | number | null) => void;
  value: string | number | null | undefined;
  testId?: string;
  info?: ReactNode;
}

const valueToString = (value: string | number | null | undefined) =>
  !_.isNil(value) ? String(value) : '';

const stringToValue = (type: InputProps.Type | undefined, value: string) =>
  type === 'number' ? (value === '' ? null : Number(value)) : value;

export const TextInput = ({
  label,
  name,
  stretch,
  adornment,
  errorMessage,
  type,
  value,
  innerRef,
  onBlur,
  onChange,
  testId,
  info,
  ...props
}: TextInputProps) => {
  const className = adornment ? 'flex md:max-w-[750px]' : '';
  // Keeping local value of state as user needs to be able to enter decimal numbers such
  // as 0.0001. Whilst typing this number, values such as 0.00 will be converted to a text value of 0
  // preventing the users from fully entering the value (as this is a controlled component)
  const [textValue, setTextValue] = useState<string>(valueToString(value));

  // Handle change of value outside of local state
  useEffect(() => {
    const stringValue = valueToString(value);
    if (stringValue !== textValue) {
      setTextValue(stringValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormField
      label={label}
      errorText={errorMessage}
      stretch={stretch}
      testId={testId}
      info={info}
    >
      <div className={className}>
        <Input
          {...{ className: 'grow' }}
          type={type}
          ref={innerRef}
          name={name}
          value={textValue}
          onBlur={onBlur}
          onChange={(e) => {
            setTextValue(e.detail.value);
            const newValue = stringToValue(type, e.detail.value);
            if (newValue === value) {
              return;
            }
            onChange(newValue);
          }}
          {...props}
        />
        {adornment}
      </div>
    </FormField>
  );
};

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  stretch?: boolean;
  adornment?: ReactNode;
  type?: InputProps.Type;
  disabled?: boolean;
  testId?: string;
}

export const ControlledInput = <T extends FieldValues>({
  name,
  label,
  control,
  type,
  stretch = true,
  adornment,
  defaultRequired,
  forceRequired,
  allowDefaultValue,
  testId,
  description,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      name={name}
      control={control}
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      render={({ field: { ref, onChange, onBlur, value } }) => (
        <TextInput
          label={label}
          errorMessage={error?.message}
          stretch={stretch}
          value={value}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
          innerRef={ref}
          adornment={adornment}
          testId={testId}
          info={
            description && (
              <HelpLink title={label} content={description} id={label} />
            )
          }
          {...props}
          disabled={readOnly || props.disabled}
        />
      )}
    />
  );
};

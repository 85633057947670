import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission, useHasPermission } from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteQuestionnaireTemplateVersionsMutation,
  useGetQuestionnaireTemplateByIdQuery,
  useGetQuestionnaireTemplateVersionsByQuestionnaireTemplateIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import {
  QuestionnaireTemplateVersionTableFields,
  useQuestionnaireTemplateVersionColumnDefinitions,
} from './config';

const Tab: FC = () => {
  const questionnaireTemplateId = useGetGuidParam('questionnaireTemplateId');
  const { addNotification } = useNotifications();

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions',
  });

  const { data: questionnaireTemplate, loading: questionnaireTemplateLoading } =
    useGetQuestionnaireTemplateByIdQuery({
      variables: { Id: questionnaireTemplateId },
    });

  const canDeleteQuestionnaireTemplateVersion = useHasPermission(
    'delete:questionnaire_template_version',
    questionnaireTemplate?.questionnaire_template,
    true
  );

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<
    QuestionnaireTemplateVersionTableFields[]
  >([]);

  const { data, loading, refetch } =
    useGetQuestionnaireTemplateVersionsByQuestionnaireTemplateIdQuery({
      variables: {
        questionnaireTemplateId,
      },
      fetchPolicy: 'no-cache',
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const [deleteQuestionnaireTemplateVersions, deleteResult] =
    useDeleteQuestionnaireTemplateVersionsMutation({
      update: (cache) => {
        evictField(cache, 'questionnaire_template_version');
      },
    });

  const handleFileOpen = () => {
    navigate('create');
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteQuestionnaireTemplateVersions({
        variables: {
          questionnaireTemplateVersionIds: selectedItems.map((s) => s.id),
        },
      });

      setSelectedItems([]);
      setIsDeleteModalVisible(false);

      await refetch();

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const columnDefinitions = useQuestionnaireTemplateVersionColumnDefinitions();

  const labelledFields = useMemo<
    QuestionnaireTemplateVersionTableFields[]
  >(() => {
    return (
      data?.questionnaire_template_version.map((a) => ({
        id: a.Id,
        createdAtTimestamp: a.CreatedAtTimestamp,
        version: a.Version,
        status: a.Status,
      })) || []
    );
  }, [data?.questionnaire_template_version]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission
              permission="insert:questionnaire_template_version"
              parentObject={questionnaireTemplate?.questionnaire_template}
              canHaveAccessAsContributor={true}
            >
              <Button formAction="none" onClick={handleFileOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        isDescending: true,
        sortingColumn: columnDefinitions[0],
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        variant="embedded"
        loading={loading || questionnaireTemplateLoading}
        items={items}
        columnDefinitions={columnDefinitions}
        selectionType={
          canDeleteQuestionnaireTemplateVersion ? 'multi' : undefined
        }
        selectedItems={selectedItems}
        trackBy="id"
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        resizableColumns={true}
        loadingText={st('loading_message')}
        sortingDisabled={false}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:questionnaire_template_version"
                    parentObject={questionnaireTemplate?.questionnaire_template}
                    canHaveAccessAsContributor={true}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedItems.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:questionnaire_template_version"
                    parentObject={questionnaireTemplate?.questionnaire_template}
                    canHaveAccessAsContributor={true}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      iconName="upload"
                      onClick={handleFileOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;

import { BadgeProps } from '@cloudscape-design/components-themed';

import { getColorStyles } from '@/utils/colours';

import styles from './style.module.scss';

interface Props extends Omit<BadgeProps, 'color'> {
  rating: { color?: string; value?: number | string | null } | null;
  showValue?: boolean;
}
export default function RatingSwatch({ rating, showValue }: Props) {
  const className = styles.swatch;
  const style = getColorStyles(rating?.color);

  return (
    <span {...{ className }} {...{ style }}>
      {showValue && rating?.value}
    </span>
  );
}

import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import ActionsTab from 'src/pages/actions/ActionsTab';
import IssuesTab from 'src/pages/issues/IssueTab';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/Permission';

import { GetControlByIdQuery, Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import IndicatorTab from '../../indicators/tab/Tab';
import { ApprovalsTab } from '../../settings/tabs/approvals/Tab';
import DetailsTab from './tabs/details/Tab';
import PerformanceTab from './tabs/performance/Tab';

export const useTabs = (
  detailsPath: string,
  control?: GetControlByIdQuery['control'][0]
) => {
  const { t, i18n } = useTranslation(['common']);
  const canViewActions = useHasPermission('read:action', control);
  const canViewIssues = useHasPermission('read:issue', control);
  const canViewTestResults = useHasPermission('read:test_result', control);
  const canViewIndicators = useHasPermission('read:indicator', control);
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', control);
  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: control && <DetailsTab control={control} />,
      href: detailsPath,
    },
  ];

  if (canViewTestResults) {
    tabs.push({
      label: i18n.format(t('performance'), 'capitalize'),
      id: 'performance',
      content: control && <PerformanceTab control={control} />,
      href: `${detailsPath}/performance`,
    });
  }

  if (canViewIssues) {
    tabs.push({
      label: i18n.format(t('issue_other'), 'capitalize'),
      id: 'issues',
      content: control && <IssuesTab parent={control} />,
      href: `${detailsPath}/issues`,
    });
  }

  if (canViewActions) {
    tabs.push({
      label: i18n.format(t('action_other'), 'capitalize'),
      id: 'actions',
      content: control && <ActionsTab parent={control} />,
      href: `${detailsPath}/actions`,
    });
  }

  if (canViewIndicators) {
    tabs.push({
      label: i18n.format(t('indicator_other'), 'capitalize'),
      id: 'indicators',
      content: control && <IndicatorTab parent={control} />,
      href: `${detailsPath}/indicators`,
    });
  }

  if (approvalsEnabled) {
    tabs.push({
      label: t('approvals.tab_title'),
      id: 'approvals',
      content: control && (
        <ApprovalsTab parent={control} approvalType={'control'} />
      ),
      href: `${detailsPath}/approvals`,
    });
  }

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: control && (
        <LinkedItemsTab
          parent={control}
          parentType={Parent_Type_Enum.Control}
        />
      ),
      href: `${detailsPath}/linked-items`,
    });
  }

  return filterEmptyTabs(tabs);
};

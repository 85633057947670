import { SelectProps } from '@cloudscape-design/components-themed';
import { FC } from 'react';

import { HidableOption } from '../ControlledMultiselect/types';
import useFilterHiddenOptions from '../ControlledMultiselect/useFilterHiddenOptions';
import Select from '.';

const HiddenOptionSelect: FC<SelectProps> = ({ options, ...rest }) => {
  const filteredOptions = useFilterHiddenOptions(
    options as HidableOption[] | undefined
  );

  return <Select {...rest} options={filteredOptions} />;
};

export default HiddenOptionSelect;

import {
  PropertyFilterOption,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import {
  ButtonDropdown,
  Container,
} from '@cloudscape-design/components-themed';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '@/components/Form/ControlledInput';
import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import { DashboardItem } from '@/components/RegisterDashboard/DashboardItem';

import { processItems } from '../../../../../node_modules/@cloudscape-design/collection-hooks/mjs/operations';
import { Box } from '../../../../theme/risksmart-themed/components';
import { CustomisableRibbonModalFields } from '../../schemas/customisableRibbonModalSchema';

type Props<T> = {
  index: number;
  items: readonly T[] | undefined;
  filteringProperties: readonly PropertyFilterProperty[];
  filteringOptions: readonly PropertyFilterOption[] | undefined;
};

const CustomisableRibbonFormFields = <T extends object>({
  index,
  items,
  filteringProperties,
  filteringOptions,
}: Props<T>) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customisableRibbons',
  });

  const { control, watch, clearErrors } =
    useFormContext<CustomisableRibbonModalFields>();
  const { remove } = useFieldArray({
    control,
    name: 'Filters',
  });

  const itemFilterQuery = watch(`Filters.${index}.itemFilterQuery`);
  const editableTitle = watch(`Filters.${index}.title`);

  const filteredItems = processItems(
    items ?? [],
    {
      propertyFilteringQuery: itemFilterQuery,
    },
    { propertyFiltering: { filteringProperties: filteringProperties ?? [] } }
  );

  const handleRemoveFilter = () => {
    remove(index);
    clearErrors();
  };

  return (
    <div className={'flex flex-row flex-grow gap-x-[80px]'}>
      <div className={'min-w-8 flex-grow'}>
        <ControlledInput
          name={`Filters.${index}.title`}
          label={'Label'}
          control={control}
        />
        <Controller
          name={`Filters.${index}.itemFilterQuery`}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <PropertyFilterPanel
                i18nStrings={{
                  ...defaultPropertyFilterI18nStrings,
                }}
                query={value}
                filteringProperties={filteringProperties}
                filteringOptions={filteringOptions}
                onChange={(e) => onChange({ ...e.detail })}
                hideOperations={false}
              />
            );
          }}
        />
      </div>
      <div className={'flex gap-x-6'}>
        <Box>
          <Box padding={{ bottom: 'xxs' }}>
            <span className={'text-grey500'}>Preview:</span>
          </Box>
          <Container disableContentPaddings={true}>
            <div className={'w-[220px] p-4 '}>
              <DashboardItem
                title={editableTitle}
                value={filteredItems.filteredItemsCount ?? 0}
                selected={false}
              />
            </div>
          </Container>
        </Box>
        <ButtonDropdown
          expandToViewport
          ariaLabel={t('removeFilterButton')}
          items={[
            {
              text: t('removeFilterButton'),
              id: 'remove_filter',
              disabled: false,
            },
          ]}
          variant="icon"
          onItemClick={handleRemoveFilter}
        />
      </div>
    </div>
  );
};

export default CustomisableRibbonFormFields;

import { TableProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import PolicyDocumentStatusBadge from '@/components/PolicyDocumentStatusBadge/PolicyDocumentStatusBadge';
import {
  ChangeRequestPartsFragment,
  Version_Status_Enum,
} from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';

export type DocumentFileTableFields = {
  version: string;
  type: string;
  status: string;
  statusValue: Version_Status_Enum;
  reviewDate?: string | null;
  reviewedBy?: string | null;
  reviewDue?: string | null;
  id: string;
  createdAtTimestamp: string;
  changeRequests: Pick<
    ChangeRequestPartsFragment,
    'ChangeRequestStatus' | 'ModifiedAtTimestamp'
  >[];
};

export const useDocumentFileColumnDefinitions = (
  editFile: (file: DocumentFileTableFields) => void
) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });

  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.columns',
  });
  const columnDefinitions: TableProps<DocumentFileTableFields>['columnDefinitions'] =
    [
      {
        id: 'version',
        header: st('version'),
        cell: (item) => (
          <Link variant="secondary" href={'#'} onFollow={() => editFile(item)}>
            {item.version}
          </Link>
        ),
        sortingField: 'version',
        isRowHeader: true,
      },

      {
        id: 'type',
        header: st('type'),
        cell: (item) => item.type,
        sortingField: 'type',
      },
      {
        id: 'status',
        header: st('status'),
        cell: (item) => {
          return (
            <PolicyDocumentStatusBadge
              item={{
                Status: item.statusValue,
                NextReviewDate: item.reviewDue,
              }}
              changeRequests={item.changeRequests}
            />
          );
        },
        sortingField: 'status',
      },
      {
        id: 'reviewDate',
        header: st('reviewDate'),
        cell: (item) => toLocalDate(item.reviewDate) || '-',
        sortingField: 'reviewDate',
      },
      {
        id: 'reviewedBy',
        header: st('reviewedBy'),
        cell: (item) => item.reviewedBy,
        sortingField: 'reviewedBy',
      },
      {
        id: 'reviewDue',
        header: st('reviewDue'),
        cell: (item) => toLocalDate(item.reviewDue) || '-',
        sortingField: 'reviewDue',
      },
      {
        id: 'createdAtTimestamp',
        header: t('created_on'),
        cell: (item) => toLocalDate(item.createdAtTimestamp) || '-',
        sortingField: 'createdAtTimestamp',
      },
    ];

  return columnDefinitions;
};

import { TableProps } from '@cloudscape-design/components-themed';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { GetIndicatorsByParentIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';

import { conformanceRatingFromResults } from '../calculateConformanceRating';

export type IndicatorTableFields =
  GetIndicatorsByParentIdQuery['indicator'][number] & {
    LatestResultDate: string | undefined;
    LatestResultValue: string | undefined;
  };

export function useIndicatorColumnDefinitions() {
  const { getLabel: getTestFreqLabel } = useRating('frequency');
  const { getByValue: statusGetByValue } = useRating(
    'indicator_conformance_status'
  );
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'indicators.columns',
  });
  const columnDefinitions: TableProps<IndicatorTableFields>['columnDefinitions'] =
    [
      {
        id: 'name',
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href={item.Id}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        isRowHeader: true,
      },
      {
        id: 'frequency',
        header: st('test_frequency'),
        cell: (item) => getTestFreqLabel(item.TestFrequency) || '-',
        sortingField: 'TestFrequency',
      },
      {
        id: 'latest_result',
        header: st('latest_result'),
        cell: (item) => {
          const result = item.LatestResultValue;
          if (_.isNil(result)) {
            return '-';
          }
          if (item.Unit) {
            return `${result} ${item.Unit}`;
          }

          return result;
        },
        sortingField: 'LatestResultValue',
      },
      {
        id: 'conformance',
        header: st('conformance'),
        cell: (item) => {
          const rating = conformanceRatingFromResults(item);

          return <SimpleRatingBadge rating={statusGetByValue(rating)} />;
        },
      },
      {
        id: 'latest_result_date',
        header: st('latest_result_date'),
        cell: (item) => toLocalDate(item.LatestResultDate) || '-',
        sortingField: 'LatestResultDate',
      },
    ];

  return columnDefinitions;
}

import { Checkbox } from '@cloudscape-design/components-themed';
import {
  ControlProps,
  isBooleanControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { FC } from 'react';

export const BooleanCell: FC<ControlProps> = (props) => {
  const { data, handleChange, path, id, enabled } = props;

  return (
    <Checkbox
      checked={!!data}
      onChange={(ev) => handleChange(path, ev.detail.checked)}
      name={id}
      disabled={!enabled}
    />
  );
};

export const booleanCellTester: RankedTester = rankWith(3, isBooleanControl);

export default withJsonFormsControlProps(BooleanCell);

import {
  Link as DefaultLink,
  LinkProps,
} from '@cloudscape-design/components-themed';
import useLink from '@risksmart-app/components/hooks/use-link';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

interface Props extends LinkProps {
  isRelativeUrl?: boolean;
}

const Link: FC<Props> = ({ onFollow, href, isRelativeUrl, ...props }) => {
  const { handleFollow } = useLink();
  const { pathname } = useLocation();

  return (
    <DefaultLink
      href={isRelativeUrl ? `${pathname}/${href}` : href}
      onFollow={
        onFollow
          ? (e) => {
              e.preventDefault();
              onFollow(e);
            }
          : handleFollow
      }
      {...props}
    />
  );
};

export default Link;

import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/Permission';

import { GetIndicatorByIdQuery, Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import DetailsTab from './tabs/details/Tab';
import ResultsTab from './tabs/results/Tab';

export const useTabs = (
  detailsPath: string,
  indicator?: GetIndicatorByIdQuery['indicator'][0]
) => {
  const { t, i18n } = useTranslation(['common']);
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', indicator);

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: indicator && <DetailsTab indicator={indicator} />,
      href: detailsPath,
    },
    {
      label: i18n.format(t('indicator_result_other'), 'capitalize'),
      id: 'results',
      content: indicator && (
        <ResultsTab indicatorType={indicator.Type} parent={indicator} />
      ),
      href: `${detailsPath}/results`,
    },
  ];

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: indicator && (
        <LinkedItemsTab
          parent={indicator}
          parentType={Parent_Type_Enum.Indicator}
        />
      ),
      href: `${detailsPath}/linked-items`,
    });
  }

  return filterEmptyTabs(tabs);
};

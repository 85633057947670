import _ from 'lodash';
import { RatingOption } from 'src/ratings/ratings';

type AppetitePerformance = 'inside' | 'outside';

export const getAppetitePerformance = ({
  controlledRating,
  UpperAppetite,
  LowerAppetite,
  controlledRatings,
  useRanges,
  posture,
}: {
  controlledRating: number | null | undefined;
  UpperAppetite?: number | null | undefined;
  LowerAppetite?: number | null | undefined;
  controlledRatings: RatingOption[];
  useRanges: boolean;
  posture: boolean | null;
}): AppetitePerformance | null => {
  if (_.isNil(controlledRating)) {
    return null;
  }

  if (posture && (_.isNil(UpperAppetite) || UpperAppetite === 0)) {
    return null;
  }

  if (
    !posture &&
    (_.isNil(UpperAppetite) ||
      _.isNil(LowerAppetite) ||
      UpperAppetite === 0 ||
      LowerAppetite === 0)
  ) {
    return null;
  }

  const lowerThreshold = useRanges
    ? controlledRatings.find((cr) => cr.value === LowerAppetite)?.range?.[0] ??
      LowerAppetite
    : LowerAppetite;
  const upperThreshold = useRanges
    ? controlledRatings.find((cr) => cr.value === UpperAppetite)?.range?.[1] ??
      UpperAppetite
    : UpperAppetite;

  if (posture) {
    if (upperThreshold == null) {
      throw new Error('upperThreshold is null');
    }

    return controlledRating <= upperThreshold ? 'inside' : 'outside';
  }
  if (upperThreshold == null) {
    throw new Error('upperThreshold is null');
  }
  if (lowerThreshold == null) {
    throw new Error('lowerThreshold is null');
  }
  if (
    controlledRating >= lowerThreshold &&
    controlledRating <= upperThreshold
  ) {
    return 'inside';
  } else {
    return 'outside';
  }
};

import { TabsProps } from '@cloudscape-design/components-themed';
import { Trans, useTranslation } from 'react-i18next';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import { Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import ControlsTab from './tabs/controls/Tab';
import DetailsTab from './tabs/details/Tab';

export const useTabs = (
  detailsPath: string,
  parent: ObjectWithContributors | undefined
): TabsProps['tabs'] => {
  const canViewControls = useHasPermission('read:control');
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', parent);
  const { t } = useTranslation('common');
  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: <DetailsTab />,
      href: detailsPath,
    },
  ];
  if (canViewControls) {
    tabs.push({
      // TODO: translation
      label: 'Controls',
      id: 'controls',
      content: parent && <ControlsTab parent={parent} />,
      href: `${detailsPath}/controls`,
    });
  }
  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: <Trans>linkedItems.tab_title</Trans>,
      id: 'linkedItems',
      content: parent && (
        <LinkedItemsTab
          parent={parent}
          parentType={Parent_Type_Enum.ControlGroup}
        />
      ),
      href: `${detailsPath}/linked-items`,
    });
  }

  return filterEmptyTabs(tabs);
};

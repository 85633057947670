import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Data_Import_Status_Enum,
  useDataImportStartImportMutation,
  useGetDataImportErrorsQuery,
  useGetDataImportStatusSubscription,
} from 'src/generated/graphql';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import TabHeader from '@/components/TabHeader';
import { useRating } from '@/hooks/use-rating';

import { useGetCollectionTableProps } from './config';

const Tab: FC = () => {
  const dataImportId = useGetGuidParam('dataImportId');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'dataImportResult',
  });
  const { addNotification } = useNotifications();
  const { data: dataImportData, loading: dataImportLoading } =
    useGetDataImportStatusSubscription({
      variables: { id: dataImportId },
    });
  const dataImportStatus = dataImportData?.data_import[0].Status;
  const [startImport, startImportResult] = useDataImportStartImportMutation({
    variables: { Id: dataImportId },
  });
  const { data, loading } = useGetDataImportErrorsQuery({
    variables: {
      dataImportId,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const { getByValue } = useRating('data_import_status');
  const tableProps = useGetCollectionTableProps(data?.data_import_error);

  return (
    <Table
      header={
        <SpaceBetween size="m">
          <SimpleRatingBadge
            data-testid="dataImportStatus"
            rating={getByValue(dataImportStatus)}
          />
          <TabHeader
            actions={
              dataImportStatus === Data_Import_Status_Enum.Valid && (
                <Button
                  disabled={startImportResult.loading || dataImportLoading}
                  onClick={() => startImport()}
                >
                  {st('startImport')}
                </Button>
              )
            }
          >
            {st('tabTitle')}
          </TabHeader>
        </SpaceBetween>
      }
      variant="embedded"
      {...tableProps}
      loading={loading}
    />
  );
};

export default Tab;

import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  namedOperations,
  Parent_Type_Enum,
  useDeleteRiskMutation,
  useGetRiskByIdQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission, useHasPermission } from 'src/rbac/Permission';
import { useGetDetailParentPath } from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  selectedTabId:
    | 'details'
    | 'controls'
    | 'ratings'
    | 'appetites'
    | 'actions'
    | 'acceptances'
    | 'indicators'
    | 'impacts'
    | 'approvals'
    | 'linkedItems';
  showDeleteButton?: boolean;
};

const Page: FC<Props> = ({ selectedTabId, showDeleteButton }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const riskId = useGetGuidParam('riskId');
  const parentUrl = useGetDetailParentPath(riskId);
  const [mutate, deleteResult] = useDeleteRiskMutation({
    update: (cache) => {
      evictField(cache, 'risk');
      evictField(cache, 'risk_aggregate');
      evictField(cache, 'acceptance');
      evictField(cache, 'appetite');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'impact_rating');
    },
    refetchQueries: [
      namedOperations.Query.getRiskById,
      namedOperations.Query.getRisksByTier,
    ],
  });
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'risks' });
  const { data, error } = useGetRiskByIdQuery({
    variables: {
      _eq: riskId,
    },
  });
  if (error) {
    throw error;
  }

  const risk = data?.risk[0];
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    risk
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    risk
  );
  const [exportItem, { loading: exporting }] = useExporter(
    riskId,
    internalAuditEnabled && canViewInternalAudit,
    complianceMonitoringEnabled && canViewCompliance
  );
  const tabs = useTabs(risk);
  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!risk) {
        return false;
      }
      await mutate({
        variables: {
          id: risk.Id,
        },
      });
      navigate(parentUrl);

      return true;
    },
  });

  if (data?.risk.length === 0) {
    throw new PageNotFound(`Risk with id ${riskId} not found`);
  }

  const counter =
    risk && `(${getFriendlyId(Parent_Type_Enum.Risk, risk.SequentialId)})`;
  const fallbackTitle = st('fallback_title');

  return (
    <PageLayout
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" disabled={exporting} onClick={exportItem}>
            {t('export')}
          </Button>

          {showDeleteButton && (
            <Permission permission="delete:risk" parentObject={risk}>
              <Button
                variant="normal"
                formAction="none"
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {st('delete_button')}
              </Button>
            </Permission>
          )}
        </SpaceBetween>
      }
      meta={{
        title: fallbackTitle,
      }}
      title={risk?.Title}
      counter={counter}
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={selectedTabId}
        variant="container"
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;

import {
  Checkbox,
  FormField,
  SegmentedControl,
  SegmentedControlProps,
  Select,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import { OptionDefinition } from '@cloudscape-design/components-themed/internal/components/option/interfaces';
import Button from '@risksmart-app/components/Button';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import Loading from '@risksmart-app/components/Loading';
import i18next from '@risksmart-app/components/providers/i18n';
import Common from '@risksmart-app/components/taxonomy/locales/default/en/common.json';
import Library from '@risksmart-app/components/taxonomy/locales/default/en/library.json';
import Rating from '@risksmart-app/components/taxonomy/locales/default/en/ratings.json';
import Taxonomy from '@risksmart-app/components/taxonomy/locales/default/en/taxonomy.json';
import { handleError } from '@risksmart-app/components/utils/errorUtils';
import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadBlob } from 'src/data/rest/fileUtils';

import DeleteModal from '@/components/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteTaxonomyOrgMutation,
  useGetTaxonomyAuditQuery,
  useInsertTaxonomyMutation,
  useUpdateTaxonomyMutation,
} from '@/generated/graphql';
import {
  useCreateResultNotification,
  useDeleteResultNotification,
} from '@/hooks/useMutationResultNotification';

import TaxonomyForm from './forms/TaxonomyForm';
import { TaxonomyDataFields } from './forms/taxonomySchema';

const Tab: FC = () => {
  useI18NSummaryHelpContent('taxonomy.help');
  const { user } = useRisksmartUser();

  const [insertTaxonomy] = useInsertTaxonomyMutation();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [showDefault, setShowDefault] = useState<boolean>(false);
  const [deleteTaxonomyOrg, deleteResult] = useDeleteTaxonomyOrgMutation();
  const [updateTaxonomy] = useUpdateTaxonomyMutation();
  const [selectedModifiedDate, setSelectedModifiedDate] = useState('');
  const { data, loading, refetch } = useGetTaxonomyAuditQuery({
    variables: {
      Locale: i18next.language,
      OrgKey: user!.orgKey!,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setSelectedModifiedDate(data.taxonomy_audit?.[0]?.ModifiedAtTimestamp);
    },

    onError: (error) => {
      console.warn(
        `Error attempting to load translations for ${user?.orgKey} : ${i18next.language}`
      );
      handleError(error);
    },
  });

  const latestTaxonomy = data?.taxonomy_audit?.[0];

  const defaults = {
    Taxonomy,
    Rating,
    Common,
    Library,
  };

  const taxonomyFields = useMemo(
    () =>
      data?.taxonomy_audit.find(
        (ta) => ta.ModifiedAtTimestamp === selectedModifiedDate
      ),
    [data?.taxonomy_audit, selectedModifiedDate]
  );

  const taxonomyToDisplay = showDefault ? defaults : taxonomyFields;

  const { t } = useTranslation(['common']);
  const taxonomyTypes: SegmentedControlProps.Option[] = [
    { id: 'Common', text: t('taxonomy.taxonomyTypes.common') },
    { id: 'Rating', text: t('taxonomy.taxonomyTypes.ratings') },
    { id: 'Taxonomy', text: t('taxonomy.taxonomyTypes.taxonomy') },
    { id: 'Library', text: t('taxonomy.taxonomyTypes.library') },
  ];

  const [selectedTaxonomy, setSelectedTaxonomy] = useState<string>(
    taxonomyTypes[0].id
  );

  const download = () => {
    const jsonObj =
      taxonomyToDisplay?.[selectedTaxonomy as keyof typeof taxonomyToDisplay];
    const json = JSON.stringify(jsonObj, null, 4);
    const blob = new Blob([json], {
      type: 'application/json',
    });
    downloadBlob(`${selectedTaxonomy}.json`, blob);
  };

  const onInsert = useCreateResultNotification({
    entityName: t('taxonomy.entity_name'),
    asyncAction: async () => {
      await insertTaxonomy();
      const { data: auditData } = await refetch();
      setSelectedModifiedDate(
        auditData?.taxonomy_audit[0].ModifiedAtTimestamp ?? ''
      );

      return true;
    },
  });

  const onDelete = useDeleteResultNotification({
    entityName: t('taxonomy.entity_name'),
    asyncAction: async () => {
      await deleteTaxonomyOrg({
        variables: {
          TaxonomyId: taxonomyFields!.Id!,
          OrgKey: user!.orgKey!,
        },
      });

      await refetch();
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  const onUpdate = async (data: TaxonomyDataFields) => {
    if (!taxonomyFields) {
      throw new Error('taxonomyFields not defined');
    }
    await updateTaxonomy({
      variables: {
        Library: JSON.parse(data.Library),
        Rating: JSON.parse(data.Rating),
        Common: JSON.parse(data.Common),
        Taxonomy: JSON.parse(data.Taxonomy),
        Id: taxonomyFields.Id,
      },
    });
    const { data: auditData } = await refetch();
    setSelectedModifiedDate(
      auditData?.taxonomy_audit[0].ModifiedAtTimestamp ?? ''
    );
  };

  const versions = useMemo(
    () =>
      data?.taxonomy_audit.map((a) => ({
        label:
          a.ModifiedAtTimestamp === data?.taxonomy_audit[0].ModifiedAtTimestamp
            ? 'Latest'
            : dayjs(a.ModifiedAtTimestamp).format('YYYY-MM-DD HH:mm:ss'),
        value: a.ModifiedAtTimestamp,
      })),
    [data?.taxonomy_audit]
  );
  const selectedVersion = useMemo<OptionDefinition | null>(
    () => versions?.find((v) => v.value === selectedModifiedDate) || null,
    [selectedModifiedDate, versions]
  );

  const numberOfOrgsUsingTaxonomy =
    latestTaxonomy?.organisations_aggregate.aggregate?.count;

  return (
    <>
      <SpaceBetween size="m">
        <TabHeader
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                disabled={loading || !taxonomyFields || showDefault}
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {t('delete')}
              </Button>
              <Button
                iconName="download"
                disabled={loading || !taxonomyToDisplay}
                onClick={download}
              >
                {t('export')}
              </Button>
            </SpaceBetween>
          }
        >
          {t('taxonomy.taxonomyTableTitle')}
        </TabHeader>
      </SpaceBetween>
      {loading ? (
        <Loading testId="loading-taxonomy" />
      ) : (
        <>
          {taxonomyFields ? (
            <div>
              <div>
                {t('taxonomy.organisationCountMessage', {
                  count: (numberOfOrgsUsingTaxonomy ?? 1) - 1,
                })}
              </div>
              <div className="mt-4">
                <FormField label="Show defaults">
                  <Checkbox
                    checked={showDefault}
                    onChange={(e) => setShowDefault(e.detail.checked)}
                  />
                </FormField>
              </div>
              {!showDefault && (
                <div className="mt-4">
                  <FormField label="Version">
                    <Select
                      selectedOption={selectedVersion}
                      options={versions}
                      statusType={loading ? 'loading' : undefined}
                      onChange={(e) =>
                        setSelectedModifiedDate(
                          e.detail.selectedOption.value ?? ''
                        )
                      }
                    />
                  </FormField>
                </div>
              )}
              <div className="my-4">
                <SegmentedControl
                  selectedId={selectedTaxonomy}
                  onChange={({ detail }) =>
                    setSelectedTaxonomy(detail.selectedId)
                  }
                  label="Select taxonomy"
                  options={taxonomyTypes}
                />
              </div>
              <TaxonomyForm
                i18n={t('taxonomy')}
                readOnly={showDefault}
                // Force rerender of form when date changes
                key={selectedModifiedDate}
                values={{
                  Library: JSON.stringify(taxonomyToDisplay?.Library, null, 4),
                  Rating: JSON.stringify(taxonomyToDisplay?.Rating, null, 4),
                  Common: JSON.stringify(taxonomyToDisplay?.Common, null, 4),
                  Taxonomy: JSON.stringify(
                    taxonomyToDisplay?.Taxonomy,
                    null,
                    4
                  ),
                }}
                selectedTaxonomy={selectedTaxonomy}
                onSave={onUpdate}
              />
            </div>
          ) : (
            <div className="my-4 text-center">
              {t('taxonomy.noTaxonomyFound')}
              <div className="mt-4">
                <Button onClick={onInsert}>{t('taxonomy.add_button')}</Button>
              </div>
            </div>
          )}
        </>
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {t('taxonomy.confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;

import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ActionFormFields from 'src/pages/actions/update/forms/ActionFormFields';
import {
  ActionFormFieldData,
  ActionFormSchema,
  defaultValues,
} from 'src/pages/actions/update/forms/actionsSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

type Props = Omit<
  FormContextProps<ActionFormFieldData>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
> & { beforeFieldsSlot?: ReactNode };

const ActionForm: FC<Props> = ({ beforeFieldsSlot, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={ActionFormSchema}
      defaultValues={defaultValues}
      i18n={t('actions')}
      formId={'action-form'}
      parentType={Parent_Type_Enum.Action}
      mapPreviewedChanges={(
        current: ActionFormFieldData | undefined,
        incoming:
          | (ActionFormFieldData & {
              Owners: { UserId: string }[];
              OwnerGroups: { UserGroupId: string }[];
              Contributors: { UserId: string }[];
              ContributorGroups: { UserGroupId: string }[];
            })
          | undefined
          | null
      ): ActionFormFieldData => {
        return {
          ...defaultValues,
          ...current,
          ...incoming,
          Owners: incoming
            ? getOwners({
                owners: incoming.Owners,
                ownerGroups: incoming.OwnerGroups,
              })
            : current?.Owners ?? [],
          Contributors: incoming
            ? getContributors({
                contributors: incoming.Contributors,
                contributorGroups: incoming.ContributorGroups,
              })
            : current?.Contributors ?? [],
        };
      }}
    >
      {beforeFieldsSlot}
      <ActionFormFields readonly={props.readOnly} />
    </CustomisableFormContext>
  );
};

export default ActionForm;

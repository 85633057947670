import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetThirdPartiesQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { addThirdPartyUrl } from '@/utils/urls';

import { SpaceBetween } from '../../../../theme/risksmart-themed/components';
import { PageLayout } from '../../layouts';
import { Permission } from '../../rbac/Permission';
import { useGetCollectionTableProps } from './config';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party',
  });
  const title = st('register_title');

  const { data, loading } = useGetThirdPartiesQuery();

  const tableProps = useGetCollectionTableProps(
    data?.third_party,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey="third_party.registerHelp"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission="insert:third_party">
            <Button variant="primary" href={addThirdPartyUrl()}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;

import { GetChangeRequestsQuery } from '@/generated/graphql';

export type ChangeRequestResponses =
  GetChangeRequestsQuery['change_request'][0]['responses'];

export const getMaxLevel = (responses: ChangeRequestResponses) => {
  if (!responses || responses.length === 0) {
    return 0;
  }

  const levels = Array.from(
    new Set(responses.map((r) => r.approver.level?.SequenceOrder ?? 0))
  ).sort((a, b) => a - b);

  return levels[levels.length - 1];
};

export const getCurrentLevel = (responses: ChangeRequestResponses) => {
  const levelsRemainingToBeApproved = Array.from(
    new Set(
      responses
        .filter((c) => !c.Approved)
        .map((r) => r.approver.level?.SequenceOrder ?? 0)
    )
  );
  if (levelsRemainingToBeApproved.length === 0) {
    return getMaxLevel(responses);
  }

  return levelsRemainingToBeApproved.sort((a, b) => a - b)[0];
};

export const getApproversForLevel = (
  responses: ChangeRequestResponses,
  level: number
) => {
  return (
    responses
      .filter((c) => c.approver.level?.SequenceOrder === level)
      .map((response, i) => ({
        id: response.approver.OwnerApprover
          ? String(i)
          : response.approver.user?.Id ?? '',
        label: response.approver.OwnerApprover
          ? 'Owner'
          : response.approver.user?.FriendlyName ?? '',
      })) ?? []
  );
};

export const getCurrentApprovers = (responses: ChangeRequestResponses) => {
  return getApproversForLevel(responses, getCurrentLevel(responses));
};

export const getNextApprovers = (responses: ChangeRequestResponses) => {
  return getApproversForLevel(responses, getCurrentLevel(responses) + 1);
};

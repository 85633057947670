import {
  PropertyFilterOption,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { Alert } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomisableRibbonFormFields from '@/components/CustomisableRibbon/CustomisableRibbonFormFields';

import { Container } from '../../../../theme/risksmart-themed/components';
import { CustomisableRibbonModalFields } from '../../schemas/customisableRibbonModalSchema';
import { Filter } from './Filter';
import styles from './style.module.scss';

interface Props<T> {
  filteringProperties: readonly PropertyFilterProperty[];
  items: readonly T[] | undefined;
  filteringOptions: readonly PropertyFilterOption[] | undefined;
  defaultFilters: Filter[];
}

const CustomisableRibbonForm = <T extends object>({
  filteringProperties,
  filteringOptions,
  items,
  defaultFilters,
}: Props<T>) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customisableRibbons',
  });

  const { watch, setValue, clearErrors, control } =
    useFormContext<CustomisableRibbonModalFields>();
  const { append } = useFieldArray({
    control,
    name: 'Filters',
  });
  const filters = watch('Filters');

  const addEditableFilter = () =>
    append({
      id: crypto.randomUUID(),
      title: 'All',
      itemFilterQuery: { tokens: [], operation: 'and' },
    });

  const handleAddFilter = () => {
    addEditableFilter();
    clearErrors();
  };

  const handleResetFilter = () => {
    setValue('Filters', defaultFilters);
    clearErrors();
  };

  return (
    <span
      data-testid="customisable-ribbon-form"
      className={'flex flex-col gap-5'}
    >
      <Alert
        statusIconAriaLabel={'Warning'}
        type={'warning'}
        header={t('ribbon_alert')}
      />
      {filters.length ? (
        filters.map((filter, index) => (
          <Container key={filter.id}>
            <div className={styles.customisableRibbonForm}>
              <CustomisableRibbonFormFields
                index={index}
                items={items}
                filteringProperties={filteringProperties}
                filteringOptions={filteringOptions}
              />
            </div>
          </Container>
        ))
      ) : (
        <Container>
          <div
            className={
              'flex flex-grow flex-col ' +
              'items-center justify-center ' +
              'max-h-[170px] gap-y-8 m-[40px]'
            }
          >
            <h3 className={'font-normal m-0'}>
              {t('restoreDefaultsDescription')}
            </h3>
            <Button onClick={handleResetFilter}>
              {t('restoreDefaultsButton')}
            </Button>
          </div>
        </Container>
      )}
      <Button onClick={handleAddFilter}>{t('addFilterButton')}</Button>
    </span>
  );
};

export default CustomisableRibbonForm;

import {
  Cards as CCards,
  CardsProps,
} from '@cloudscape-design/components-themed';

import styles from './style.module.scss';

const Cards = <T extends object>(props: CardsProps<T>) => {
  return (
    <div className={styles.cards}>
      <CCards {...props} />
    </div>
  );
};

export default Cards;

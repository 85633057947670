import { JsonFormsRendererRegistryEntry } from '@jsonforms/core';
import BooleanCell, {
  booleanCellTester,
} from '@risksmart-app/components/renderers/cells/BooleanCell';
import TextCell, {
  textCellTester,
} from '@risksmart-app/components/renderers/cells/TextCell';
import {
  VerticalLayoutRenderer,
  verticalLayoutTester,
} from '@risksmart-app/components/renderers/layouts/VerticalLayout';

import TextControl, { textControlTester } from './controls/TextControl';
import { GroupLayoutRenderer, groupLayoutTester } from './layouts/GroupLayout';

const rendererRegistry: JsonFormsRendererRegistryEntry[] = [
  {
    tester: verticalLayoutTester,
    renderer: VerticalLayoutRenderer,
  },
  {
    tester: groupLayoutTester,
    renderer: GroupLayoutRenderer,
  },
  {
    tester: textControlTester,
    renderer: TextControl, // TODO: Check if this is a duplicate of TextCell
  },
  {
    tester: textCellTester,
    renderer: TextCell,
  },
  {
    tester: booleanCellTester,
    renderer: BooleanCell,
  },
];

export default rendererRegistry;

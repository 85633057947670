import {
  FormField,
  SelectProps,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import _ from 'lodash';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '../ControlledInput';
import ControlledSelect from '../ControlledSelect';
import { ControlledSwitch, Switch } from '../ControlledSwitch/ControlledSwitch';
import ControlledTextarea from '../ControlledTextarea';
import { CustomFieldContext } from '../CustomAttributes/Context/CustomFieldContext';
import {
  FieldType,
  FormFieldOptions,
} from '../CustomAttributes/EditFields/NewFieldSchema';
import { ControlledBaseProps } from '../types';

function CustomisableFieldOptions({
  defaultValueOptions,
  forceRequired,
  allowDefaultValue,
}: {
  defaultValueOptions: SelectProps.Options;
  forceRequired: boolean;
  allowDefaultValue: boolean;
}) {
  const { control, watch, setValue } = useFormContext<FormFieldOptions>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'customAttributes.fields',
  });
  const customField = useContext(CustomFieldContext);

  let defaultValueInput: ReactNode;
  const defaultValueDefaultProps: ControlledBaseProps<FormFieldOptions> & {
    testId: string;
  } = {
    name: 'DefaultValue',
    control,
    label: st('defaultValue'),
    testId: 'DefaultValue',
  };
  if (customField) {
    switch (customField.currentField.Type) {
      case FieldType.Text:
      case FieldType.Link:
        defaultValueInput = <ControlledInput {...defaultValueDefaultProps} />;
        break;
      case FieldType.Textarea:
        defaultValueInput = (
          <ControlledTextarea {...defaultValueDefaultProps} />
        );
        break;
      case FieldType.Select:
        defaultValueInput = (
          <ControlledSelect
            {...defaultValueDefaultProps}
            options={
              customField.currentField.Options?.map((o) => ({
                value: o,
                label: o,
              })) ?? []
            }
          />
        );
        break;
    }
  } else {
    if (defaultValueOptions.length > 0) {
      defaultValueInput = (
        <ControlledSelect
          {...defaultValueDefaultProps}
          options={defaultValueOptions}
        />
      );
    } else {
      defaultValueInput = <ControlledInput {...defaultValueDefaultProps} />;
    }
  }

  const required = watch('Required');
  const hidden = watch('Hidden');
  const readOnly = watch('ReadOnly');
  const defaultValue = watch('DefaultValue');
  const [enableDefaultValue, setEnableDefaultValue] = useState<boolean>(
    () => !_.isNil(defaultValue)
  );

  useEffect(() => {
    if (required) {
      setValue('Hidden', false);
      setValue('ReadOnly', false);
    }
  }, [required, setValue]);

  useEffect(() => {
    if (hidden) {
      setValue('Required', false);
      setValue('ReadOnly', false);
    }
  }, [hidden, setValue]);

  useEffect(() => {
    if (readOnly) {
      setValue('Required', false);
      setValue('Hidden', false);
    }
  }, [readOnly, setValue]);

  return (
    <FormField label={st('options')}>
      <SpaceBetween size="xxs" direction="vertical">
        <ControlledSwitch
          disabled={forceRequired}
          name="Required"
          control={control}
          label={st('required')}
          testId="Required"
        />
        <ControlledSwitch
          disabled={forceRequired}
          name="Hidden"
          control={control}
          label={st('hidden')}
          testId="Hidden"
        />
        <ControlledSwitch
          disabled={forceRequired}
          name="ReadOnly"
          control={control}
          label={st('readOnly')}
          testId="ReadOnly"
        />
        {allowDefaultValue && (
          <>
            <Switch
              name="EnableDefaultValue"
              checked={enableDefaultValue}
              label={st('setDefaultValue')}
              testId="EnableDefaultValue"
              onChange={(e) => {
                if (!e.detail.checked) {
                  setValue('DefaultValue', null);
                }
                setEnableDefaultValue(e.detail.checked);
              }}
            />
            {enableDefaultValue && defaultValueInput}
          </>
        )}
      </SpaceBetween>
    </FormField>
  );
}

export default CustomisableFieldOptions;

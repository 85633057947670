import { JsonSchema7 } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import DeleteButton from '@risksmart-app/web/src/components/DeleteButton';
import DeleteModal from '@risksmart-app/web/src/components/DeleteModal';
import { ErrorObject } from 'ajv';
import { FC, useState } from 'react';

import {
  Modal,
  SpaceBetween,
  TextContent,
} from '../../theme/risksmart-themed/components';
import Button from '../Button';
import rendererRegistry from '../renderers/registry';
import {
  QuestionData,
  questionSchema,
  questionUISchema,
} from './form-configs/question';
import {
  CustomSchema,
  CustomUISchema,
  CustomUISchemaElement,
  useFormBuilderContext,
} from './useFormBuilderContext';
import { useFormState } from './useFormState';

export type EditFormQuestionModalProps = {
  action: 'add' | 'edit';
  parentId: string;
  questionData: QuestionData;
  setQuestionData: (questionData: QuestionData) => void;
  isEditingQuestion: boolean;
  setIsEditingQuestion: (isEditingQuestion: boolean) => void;
  initialQuestionData: QuestionData;
  uiSchema: CustomUISchema | CustomUISchemaElement;
};

export const FormQuestionModal: FC<EditFormQuestionModalProps> = ({
  action,
  parentId,
  questionData,
  setQuestionData,
  isEditingQuestion,
  setIsEditingQuestion,
  initialQuestionData,
  uiSchema,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>(undefined);

  const {
    isFormCustomisable,
    uiSchema: fullUISchema,
    schema,
    setSchema,
    setUISchema,
  } = useFormBuilderContext();

  const { setIsFormDirty } = useFormState();

  const uuid = `question_${crypto.randomUUID()}`;

  const resetModal = () => {
    setQuestionData(initialQuestionData);
    setIsEditingQuestion(false);
    setIsFormDirty(false);
  };

  const handleEditQuestionCancel = () => {
    resetModal();
  };

  const handleAddNewQuestion = () => {
    setSchema({
      ...schema,
      properties: {
        ...schema.properties,
        [uuid]: {
          type: 'string',
          parentId,
          isCustomisable: true,
        },
      },
    } as CustomSchema);

    const modifiedUISchemaElementsCopy = fullUISchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === parentId) {
          return {
            ...element,
            elements: [
              ...(element?.elements || []),
              {
                type: 'Control',
                label: questionData.questionTitle,
                id: uuid,
                parentId,
                scope: `#/properties/${uuid}`,
                options: {
                  placeholder: questionData.placeholder,
                },
              },
            ],
          };
        }

        return element;
      }
    );

    setUISchema({
      ...fullUISchema,
      elements: modifiedUISchemaElementsCopy,
    });

    resetModal();
  };

  const handleUpdateQuestion = () => {
    // TODO: In a future PR, handle schema change if question input type changes

    const modifiedUISchemaElementsCopy = fullUISchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === parentId) {
          const modifiedElements = element?.elements
            ? element.elements.map((childElement: CustomUISchemaElement) => {
                if (childElement.id === uiSchema?.id) {
                  return {
                    ...childElement,
                    label: questionData.questionTitle,
                    options: {
                      placeholder: questionData.placeholder,
                    },
                  };
                }

                return childElement;
              })
            : [];

          return {
            ...element,
            elements: modifiedElements,
          };
        }

        return element;
      }
    );

    setUISchema({
      ...fullUISchema,
      elements: modifiedUISchemaElementsCopy,
    });

    resetModal();
  };

  const handleSaveQuestion = () => {
    if (errors?.length) {
      setIsFormDirty(true);

      return;
    }

    if (action === 'add') {
      handleAddNewQuestion();
    }

    if (action === 'edit') {
      handleUpdateQuestion();
    }
  };

  const handleDeleteQuestion = () => {
    if (!uiSchema?.id) {
      console.log('No id found in uiSchema');

      return;
    }

    if (!schema?.properties) {
      console.log('No properties found in schema');

      return;
    }

    const modifiedUISchemaElementsCopy = fullUISchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === parentId) {
          const filteredElements = element?.elements
            ? element.elements.filter(
                (elementElement: CustomUISchemaElement) =>
                  elementElement.id !== uiSchema.id
              )
            : [];

          return {
            ...element,
            elements: filteredElements,
          };
        }

        return element;
      }
    );

    setUISchema({
      ...fullUISchema,
      elements: modifiedUISchemaElementsCopy,
    });

    const modifiedSchemaPropertiesCopy = Object.keys(
      schema.properties || {}
    ).reduce((acc, key) => {
      if (key !== uiSchema.id) {
        acc![key] = schema.properties![key];
      }

      return acc;
    }, {} as JsonSchema7['properties']);

    setSchema({
      ...schema,
      properties: { ...modifiedSchemaPropertiesCopy },
    });

    resetModal();
  };

  return (
    <>
      {isEditingQuestion ? (
        <>
          <Modal
            onDismiss={resetModal}
            visible={isEditingQuestion}
            header={action === 'add' ? 'Add Question' : 'Edit Question'} // TODO: Translation
            footer={
              <div className={'flex justify-between'}>
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" onClick={handleSaveQuestion}>
                    Save {/* TODO: Translation */}
                  </Button>
                  <Button variant="normal" onClick={handleEditQuestionCancel}>
                    Cancel {/* TODO: Translation */}
                  </Button>
                </SpaceBetween>
                {action === 'edit' ? (
                  <DeleteButton onClick={() => setIsDeleteModalVisible(true)}>
                    Delete {/* TODO: Translation */}
                  </DeleteButton>
                ) : null}
              </div>
            }
          >
            <JsonForms
              data={questionData}
              readonly={!isFormCustomisable}
              schema={questionSchema}
              uischema={questionUISchema}
              renderers={rendererRegistry}
              onChange={({ data, errors }) => {
                const formattedErrors = errors?.map((error) => ({
                  ...error,
                  dataPath: '',
                })) as ErrorObject[] | undefined;

                setErrors(formattedErrors);
                setQuestionData(data);
              }}
            />
          </Modal>

          <DeleteModal
            loading={false}
            isVisible={isDeleteModalVisible}
            onDelete={handleDeleteQuestion}
            onDismiss={() => setIsDeleteModalVisible(false)}
            header={'Delete'}
          >
            <TextContent>
              <p>Are you sure you want to delete this question?</p>
              <p>This action cannot be undone.</p>
            </TextContent>
          </DeleteModal>
        </>
      ) : null}
    </>
  );
};

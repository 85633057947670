import {
  DateRangePicker,
  DateRangePickerProps,
} from '@cloudscape-design/components-themed';
import { FormField } from '@risksmart-app/web/src/components/Form/Form/FormField';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isValidDateString } from '@/utils/dateUtils';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import { ControlledBaseProps } from '../types';

interface DateRangePickerInputProps {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  value: DateRangePickerProps.Value | null;
  relativeOptions?: DateRangePickerProps.RelativeOption[];
  onChange?: (value: DateRangePickerProps.Value | null) => void;
  isExpandable?: boolean;
}

export const defaultRelativeOptions: DateRangePickerProps.RelativeOption[] = [
  {
    key: '1-week-previous',
    amount: -1,
    unit: 'week',
    type: 'relative',
  },
  {
    key: '1-week-next',
    amount: 1,
    unit: 'week',
    type: 'relative',
  },
  {
    key: '1-month-previous',
    amount: -1,
    unit: 'month',
    type: 'relative',
  },
  {
    key: '1-month-next',
    amount: 1,
    unit: 'month',
    type: 'relative',
  },
  {
    key: '3-months-previous',
    amount: -3,
    unit: 'month',
    type: 'relative',
  },
  {
    key: '3-months-next',
    amount: 3,
    unit: 'month',
    type: 'relative',
  },
  {
    key: '6-months-previous',
    amount: -6,
    unit: 'month',
    type: 'relative',
  },
  {
    key: '6-months-next',
    amount: 6,
    unit: 'month',
    type: 'relative',
  },
  {
    key: '1-year-previous',
    amount: -1,
    unit: 'year',
    type: 'relative',
  },
  {
    key: '1-year-next',
    amount: 1,
    unit: 'year',
    type: 'relative',
  },
];

export const createDefaultValidator =
  (t: TFunction<'common'>) =>
  (
    range: DateRangePickerProps.Value | null
  ): DateRangePickerProps.ValidationResult => {
    if (range?.type === 'absolute') {
      if (
        !isValidDateString(range.startDate) ||
        !isValidDateString(range.endDate)
      ) {
        return {
          valid: false,
          errorMessage: t('dateRangeInput.missingStartOrEndDateError'),
        };
      }

      if (dayjs(range.startDate).isAfter(dayjs(range.endDate))) {
        return {
          valid: false,
          errorMessage: t('dateRangeInput.startDateAfterEndDateError'),
        };
      }
    }

    if (range?.type === 'relative') {
      if (!range.amount) {
        return {
          valid: false,
          errorMessage: t('dateRangeInput.relativeAmountEmptyError'),
        };
      }

      const timeLimit = 10;
      const isTooManyYears =
        range.unit === 'year' &&
        (range.amount < -timeLimit || range.amount > timeLimit);
      const isTooManyMonths =
        range.unit === 'month' &&
        (range.amount < -12 * timeLimit || range.amount > 12 * timeLimit);
      const isTooManyWeeks =
        range.unit === 'week' &&
        (range.amount < -52 * timeLimit || range.amount > 52 * timeLimit);
      const isTooManyDays =
        range.unit === 'day' &&
        (range.amount < -365 * timeLimit || range.amount > 365 * timeLimit);
      if (
        isTooManyYears ||
        isTooManyMonths ||
        isTooManyWeeks ||
        isTooManyDays
      ) {
        return {
          valid: false,
          errorMessage: t('dateRangeInput.relativeAmountTooLargeError'),
        };
      }
    }

    return { valid: true };
  };

export const DateRangePickerInput = ({
  value,
  placeholder,
  relativeOptions,
  disabled,
  onChange,
  isExpandable = false,
}: DateRangePickerInputProps) => {
  const { t } = useTranslation('common');
  const translations = t('dateRangeInput', { returnObjects: true });

  return (
    <DateRangePicker
      value={value}
      onChange={(e) => onChange?.(e.detail.value)}
      placeholder={placeholder}
      relativeOptions={relativeOptions || defaultRelativeOptions}
      isValidRange={createDefaultValidator(t)}
      disabled={disabled}
      expandToViewport={isExpandable}
      dateOnly
      absoluteFormat={'long-localized'}
      hideTimeOffset={true}
      i18nStrings={{
        formatRelativeRange: ({ unit, amount }) => {
          const isPast = amount < 0;

          return isPast
            ? t(`relativeTimes.previous.${unit}`, { count: -amount })
            : t(`relativeTimes.next.${unit}`, { count: amount });
        },
        ...translations,
      }}
    />
  );
};

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  stretch?: boolean;
  disabled?: boolean;
  onChange?: (value: DateRangePickerProps.Value | null) => void;
}

export const ControlledDateRangePicker = <T extends FieldValues>({
  name,
  label,
  control,
  placeholder,
  onChange,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      name={name}
      control={control}
      render={({ field: { value, onChange: formOnChange } }) => (
        <FormField label={label} errorText={error?.message}>
          <DateRangePickerInput
            value={value}
            onChange={(val) => {
              formOnChange(val);
              onChange?.(val);
            }}
            placeholder={placeholder}
            disabled={readOnly}
            {...props}
          />
        </FormField>
      )}
    />
  );
};

import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/Permission';

import { GetActionByIdQuery, Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import DetailsTab from './tabs/details/Tab';
import UpdatesTab from './tabs/updates/Tab';

export const useTabs = (
  parentPath: string,
  action: GetActionByIdQuery['action'][number] | undefined
) => {
  const { t } = useTranslation('common');

  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', action);

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: <DetailsTab />,
      href: parentPath,
    },
  ];

  tabs.push({
    label: 'Updates',
    id: 'updates',
    content: action && <UpdatesTab action={action} />,
    href: `${parentPath}/updates`,
  });

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: action && (
        <LinkedItemsTab
          parent={action}
          parentType={Parent_Type_Enum.Action}
          includeAssessments={true}
        />
      ),
      href: `${parentPath}/linked-items`,
    });
  }

  return filterEmptyTabs(tabs);
};

import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteCausesMutation,
  useGetCausesByParentIssueIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import CauseModal from './CauseModal';
import { CausesFields, useCausesColumnDefinitions } from './config';

const translationKeyPrefix = 'causes';
type ItemType = CausesFields;

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('causes.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: translationKeyPrefix,
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [causeId, setCauseId] = useState<string | undefined>();
  const issueId = useGetGuidParam('issueId');
  const { addNotification } = useNotifications();
  const [selectedCauses, setSelectedCause] = useState<ItemType[]>([]);
  const [deleteCauses, deleteResult] = useDeleteCausesMutation({
    update: (cache) => {
      evictField(cache, 'cause');
      evictField(cache, 'cause_aggregate');
    },
  });
  const canDeleteCauses = useHasPermission('delete:cause', parent);
  const { data, loading } = useGetCausesByParentIssueIdQuery({
    variables: {
      _eq: issueId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleCauseModalOpen = () => {
    setIsEditorOpen(true);
  };

  const handleCauseModalClose = () => {
    setCauseId(undefined);
    setIsEditorOpen(false);
  };

  const causeColumnDefinitions = useCausesColumnDefinitions((cause) => {
    setCauseId(cause.Id);
    handleCauseModalOpen();
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteCauses({
        variables: {
          Ids: selectedCauses.map((c) => c.Id),
        },
      });

      setSelectedCause([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const { items, collectionProps } = useCollection(data?.cause || [], {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:cause" parentObject={parent}>
              <Button formAction="none" onClick={handleCauseModalOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: causeColumnDefinitions[0],
      },
    },
  });

  if (!issueId) {
    throw new Error('No issue ID provided');
  }

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteCauses ? 'multi' : undefined}
        selectedItems={selectedCauses}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedCause(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission permission="delete:cause" parentObject={parent}>
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedCauses.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission permission="insert:cause" parentObject={parent}>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleCauseModalOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={causeColumnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isEditorOpen && (
        <CauseModal
          causeId={causeId}
          onDismiss={handleCauseModalClose}
          issueId={issueId}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setSelectedCause([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
